import RegisterPageComponent from "./components/RegisterPageComponent";

import { useDispatch } from "react-redux";
import { registerUserReduxAction } from "../redux/actions/auth/userActions";
const RegisterPage = () => {
  const dispatch = useDispatch();

  return (
    <RegisterPageComponent
      dispatch={dispatch}
      registerUserReduxAction={registerUserReduxAction}
    />
  );
};

export default RegisterPage;
