import React from 'react';
import { CloseButton, Row, Table } from 'react-bootstrap';

const ProductAttributes = ({ attributesTable, handleDeleteAttribute }) => {
  return (
    <div>
      <Row className='mt-3'>
        <Table hover>
          <thead>
            <tr>
              <th>Attribute</th>
              <th>Value</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {attributesTable &&
              attributesTable.map((item, idx) => (
                <tr key={idx}>
                  <td>{item.key}</td>
                  <td>{item.value.join(', ')}</td>
                  <td>
                    <CloseButton
                      onClick={() => handleDeleteAttribute(item.key)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Row>
    </div>
  );
};

export default ProductAttributes;
