import React from 'react';
import ProductDetailsPageComponent from './components/ProductDetailsPageComponent';
import axios from 'axios';
import { addToCartReduxAction } from '../redux/actions/cart/cartActions';
import { useDispatch } from 'react-redux';
const fetchProduct = async (id) => {
  const { data } = await axios.get(`/api/products/get-one/${id}`);
  return data;
};

const ProductDetailsPage = () => {
  const dispatch = useDispatch();
  const reduxDispatch = dispatch;
  return (
    <ProductDetailsPageComponent
      fetchProduct={fetchProduct}
      addToCartReduxAction={addToCartReduxAction}
      reduxDispatch={reduxDispatch}
    />
  );
};

export default ProductDetailsPage;
