import React from 'react';
import AdminSubsPageComponent from './components/AdminSubsPageComponent';
import axios from 'axios';

const fetchSubs = async () => {
  const { data } = await axios.get('/api/subs');
  return data;
};

const deleteSub = async (id) => {
  const { data } = await axios.delete(`/api/subs/delete/${id}`);
  return data;
};

const AdminSubsPage = () => {
  return (
    <AdminSubsPageComponent
      fetchSubs={fetchSubs}
      deleteSub={deleteSub}
    />
  );
};

export default AdminSubsPage;
