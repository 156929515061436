import React, { useState } from "react";
import AdminEditProductPageComponent from "./components/AdminEditProductPageComponent";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import {
  deleteCategoryReduxAction,
  insertNewCategoryReduxAction,
  saveAttributeToCatDoc,
} from "../../redux/actions/category/categoryActions";
import {
  deleteSubcategoryReduxAction,
  insertNewSubReduxAction,
} from "../../redux/actions/subs/subActions";

const uploadImageApiRequest = async (formData) => {
  const { data } = await axios.post(
    "/api/products/admin/upload-images",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};

const updateProduct = async (id, formData) => {
  // Log FormData contents; this will not work directly because formData.entries() is not immediately convertible to an object
  // Consider using formData.forEach() to log the contents or remove the logging of formData entries as it might be misleading

  try {
    const { data } = await axios.put(
      `/api/products/admin/update/${id}`,
      formData,
      {
        // Remove Content-Type header to let the browser set it with the correct boundary
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  } catch (error) {
    error.response && error.response.data && error.response.data.message
      ? error.response.data.message
      : error.message || "Unknown error";
    toast.error(errorMessage);
  }
};

const updateCategoryAttrs = async (categoryId, newAttributes) => {
  const { data } = await axios.post(`/api/categories/update-attrs`, {
    categoryId,
    newAttributes,
  });
  return data;
};

const fetchProduct = async (id) => {
  const { data } = await axios.get(`/api/products/get-one/${id}`);
  return data;
};
const AdminEditProductPage = () => {
  const { categories } = useSelector((state) => state.getCategories);

  const { subs } = useSelector((state) => state.getSubs);

  const dispatch = useDispatch();
  const reduxDispatch = dispatch;
  return (
    <AdminEditProductPageComponent
      categories={categories}
      subs={subs}
      updateProduct={updateProduct}
      reduxDispatch={reduxDispatch}
      insertNewCategoryReduxAction={insertNewCategoryReduxAction}
      insertNewSubReduxAction={insertNewSubReduxAction}
      deleteCategoryReduxAction={deleteCategoryReduxAction}
      deleteSubcategoryReduxAction={deleteSubcategoryReduxAction}
      fetchProduct={fetchProduct}
      updateCategoryAttrs={updateCategoryAttrs}
      saveAttributeToCatDoc={saveAttributeToCatDoc}
      uploadImageApiRequest={uploadImageApiRequest}
    />
  );
};

export default AdminEditProductPage;
