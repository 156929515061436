import React from 'react';
import { CloseButton, Form } from 'react-bootstrap';

const SubCategorySelector = ({
  categoryChosen,
  subs,
  selectedSubs,
  handleSubCategoryChange,
  handleDeleteSubCategoryHandler,
}) => {
  return (
    <div>
      {categoryChosen && (
        <Form.Group controlId='formBasicSubCategory'>
          <Form.Label className='text-primary'>
            Sub Category
            <CloseButton onClick={handleDeleteSubCategoryHandler} />(
            <small>remove selected</small>)
          </Form.Label>
          {subs &&
            subs
              .filter((s) => s.parentId === categoryChosen)
              .map((s) => (
                <Form.Check
                  key={s._id}
                  id='selectedSubCategory'
                  value={s._id}
                  label={s.name}
                  type='checkbox'
                  name='subs'
                  checked={selectedSubs.includes(s._id)}
                  onChange={handleSubCategoryChange}
                />
              ))}
        </Form.Group>
      )}
    </div>
  );
};

export default SubCategorySelector;
