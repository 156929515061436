import React from 'react';
import AdminCategoriesPageComponent from './components/AdminCategoriesPageComponent';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCategoryReduxAction } from '../../redux/actions/category/categoryActions';
const AdminCategoriesPage = () => {
  const { categories } = useSelector((state) => state.getCategories);
  const dispatch = useDispatch();
  const reduxDispatch = dispatch;
  return (
    <AdminCategoriesPageComponent
      categories={categories}
      deleteCategoryReduxAction={deleteCategoryReduxAction}
      reduxDispatch={reduxDispatch}
    />
  );
};

export default AdminCategoriesPage;
