import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import axios from "axios";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";
import "../../css/shared-styles.css"; // Import shared styles

const CategoryFilter = ({
  categories,
  selectedCategory,
  selectedSubcategory,
  onCategoryChange,
  onSubcategoryChange,
}) => {
  const [subs, setSubs] = useState([]);
  const [loadingSubs, setLoadingSubs] = useState(false);
  const [subsError, setSubsError] = useState(null);

  useEffect(() => {
    const fetchSubs = async () => {
      if (selectedCategory && selectedCategory.value) {
        setLoadingSubs(true);
        try {
          const { data } = await axios.get(
            `/api/subs/${selectedCategory.value}`
          );

          console.log("Fetched subcategories:", data);
          setSubs(
            data.map((sub) => ({
              value: sub._id,
              label: sub.name,
            }))
          );
          setSubsError(null);
        } catch (error) {
          console.error("Failed to fetch subcategories", error);
          setSubs([]);
          setSubsError("Failed to fetch subcategories");
        } finally {
          setLoadingSubs(false);
        }
      } else {
        setSubs([]);
        onSubcategoryChange(null);
      }
    };

    fetchSubs();
  }, [selectedCategory, onSubcategoryChange]);

  const categoryOptions = categories.map((category) => ({
    value: category._id,
    label: category.name,
  }));

  const handleCategoryChange = useCallback(
    (selected) => {
      onCategoryChange(selected);
      onSubcategoryChange(null);
    },
    [onCategoryChange, onSubcategoryChange]
  );

  return (
    <div className="filter-group mt-3">
      <label htmlFor="category-select" className="mb-2">
        <span>
          <h4>Category</h4>
        </span>
      </label>
      <Select
        id="category-select"
        name="category"
        options={categoryOptions}
        value={selectedCategory}
        onChange={handleCategoryChange}
        placeholder="Select Category"
        isClearable
      />

      {loadingSubs ? (
        <Spinner animation="border" variant="primary" />
      ) : subsError ? (
        <p style={{ color: "red" }}>{subsError}</p>
      ) : subs.length > 0 ? (
        <div className="filter-group mt-3">
          <label htmlFor="subcategory-select">
            <span>
              <h4>Product type</h4>
            </span>
          </label>
          <Select
            id="subcategory-select"
            name="subcategory"
            options={subs}
            value={selectedSubcategory}
            onChange={onSubcategoryChange}
            placeholder={`Choose from ${
              selectedCategory?.label || "the selected category"
            }`}
            isClearable
          />
        </div>
      ) : selectedCategory ? (
        <p>No subcategories available for the selected category.</p>
      ) : null}
    </div>
  );
};

CategoryFilter.propTypes = {
  categories: PropTypes.array.isRequired,
  selectedCategory: PropTypes.object,
  selectedSubcategory: PropTypes.object,
  onCategoryChange: PropTypes.func.isRequired,
  onSubcategoryChange: PropTypes.func.isRequired,
};

export default CategoryFilter;
