import React, { useState } from "react";
import { Col, Form, Image, ListGroup, Row } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import "../../css/cart-page.css";

const CartItemComponent = ({
  item,
  orderCreated = false,
  changeCount = false,
  removeFromCartHandler = false,
}) => {
  const { image, price, count, name } = item;
  const [localQuantity, setLocalQuantity] = useState(item.quantity);

  return (
    <ListGroup.Item>
      <Row className="align-items-center mt-4">
        {/* Image */}
        <Col xs={12} sm={3} md={2} className="text-center">
          <Image
            crossOrigin="anonymous"
            style={{ height: "100px", width: "100px" }}
            src={image.uri ? image.uri : ""}
            alt="academy_costumes_images"
            fluid
          />
        </Col>

        {/* Product Name */}
        <Col xs={12} sm={3} md={2} className="text-center mt-2 mt-sm-0">
          <h3>
            <b>{name}</b>
          </h3>
        </Col>

        {/* Price */}
        <Col xs={12} sm={3} md={2} className="text-center mt-2 mt-sm-0">
          <span className="pound-sign">£</span>
          <span className="price-value">{price}</span>
        </Col>

        {/* Select Box and Remove Button Group */}
        <Col xs={12} sm={6} md={6}>
          <div className="select-trash-group d-flex justify-content-between align-items-center">
            {/* Quantity Selector */}
            <Form.Select
              className="quantity-selector"
              disabled={orderCreated}
              value={localQuantity}
              onChange={(e) => {
                const selectedValue = e.target.value;
                const numValue = Number(selectedValue);
                setLocalQuantity(numValue);
                changeCount(item.productID, numValue);
              }}
            >
              <option
                disabled={!localQuantity && localQuantity === ""}
                value=""
              >
                Please select
              </option>
              {[...Array(count).keys()].map((x) => (
                <option key={x} value={x + 1}>
                  {x + 1}
                </option>
              ))}
            </Form.Select>

            {/* Remove Button */}
            <FaTrash
              size={24}
              className="trash-button ms-3"
              onClick={() => removeFromCartHandler(item.productID)}
            />
          </div>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default CartItemComponent;
