import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";

import CartPage from "./pages/CartPage";
import RegisterPage from "./pages/RegisterPage";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import UserProfilePage from "./pages/user/UserProfilePage";
import UserCartDetailsPage from "./pages/user/UserCartDetailsPage";
import UserOrderDetailsPage from "./pages/user/UserOrderDetailsPage";
import UserOrdersPage from "./pages/user/UserOrdersPage";

import AdminAnalyticsPage from "./pages/admin/AdminAnalyticsPage";
import AdminChatsPage from "./pages/admin/AdminChatsPage";
import AdminOrderDetailsPage from "./pages/admin/AdminOrderDetailsPage";
import AdminOrdersPage from "./pages/admin/AdminOrdersPage";
import AdminEditProductPage from "./pages/admin/AdminEditProductPage";
import AdminCreateProductPage from "./pages/admin/AdminCreateProductPage";
import AdminProductsPage from "./pages/admin/AdminProductsPage";
import AdminEditUserPage from "./pages/admin/AdminEditUserPage";
import AdminUsersPage from "./pages/admin/AdminUsersPage";
import RoutesWithUserChatComponent from "./components/user/RoutesWithUserChatComponent";
import ScrollToTop from "./utils/ScrollToTop";
import SignInPage from "./pages/SignInPage";

import { ToastContainer } from "react-toastify";

import UserProtectedRoutes from "./components/UserProtectedRoutes";
import AdminProtectedRoutes from "./components/AdminProtectedRoutes";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesReduxAction } from "./redux/actions/category/categoryActions";
import AdminSubsPage from "./pages/admin/AdminSubsPage";
import AdminCreateSubsPage from "./pages/admin/AdminCreateSubsPage";
import AdminEditSubPage from "./pages/admin/AdminEditSubPage";
import {
  fetchAttributes,
  fetchFilteredMenProducts,
  fetchProducts,
} from "./redux/actions/product/product";
import { getSubsReduxAction } from "./redux/actions/subs/subActions";
import { logoutUserReduxAction } from "./redux/actions/auth/userActions";
import axios from "axios";
import AdminCategoriesPage from "./pages/admin/AdminCategoriesPage";
import AdminEditCategoryPage from "./pages/admin/AdminEditCategoryPage";
import AdminCreateCategoryPage from "./pages/admin/AdminCreateCategoryPage";
import CategoryPage from "./pages/product/CategoryPage";
import SubCategoryPage from "./pages/product/SubCategoryPage";
import ShopPage from "./pages/Shop/ShopPage";
import RequestResetPasswordScreen from "./components/RequestResetPasswordScreen";
import ResetPasswordScreen from "./components/ResetPasswordScreen";
import MenPage from "./pages/mainPages/MenPage";
import WomenPage from "./pages/mainPages/WomenPage";
import BespokePage from "./pages/mainPages/BespokePage";
import TextilePage from "./pages/mainPages/TextilePage";
import HeaderComponent from "./components/navs/HeaderComponent";
import FooterComponent from "./components/navs/FooterComponent";
import ResponsiveLayout from "./components/navs/ResponsiveLayout";

function App() {
  // Example of handling page reload or state re-initialization
  const { userInfo } = useSelector((state) => state.authUser);
  const dispatch = useDispatch(); // Hook to dispatch actions

  useEffect(() => {
    dispatch(getCategoriesReduxAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSubsReduxAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchAttributes());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchFilteredMenProducts());
  }, [dispatch]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const expiration =
        sessionStorage.getItem("expiration") ||
        localStorage.getItem("expiration");
      const userInfo =
        localStorage.getItem("userInfo") || sessionStorage.getItem("userInfo");

      if (Date.now() > parseInt(expiration, 10) && userInfo) {
        dispatch(logoutUserReduxAction());
        window.location.href = "/sign-in"; // Correctly assign a URL string
      }
    }, 3000);

    return () => clearInterval(intervalId); // clean up the interval on component unmount
  }, [dispatch]);

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response === 401 && error.response.data.redirect) {
        // Redirect to login page
        window.location.href = "/sign-in"; // Correctly assign a URL string
      }
      return Promise.reject(error);
    }
  );

  return (
    <BrowserRouter>
      <div>
        <header className="App-header">
          <ResponsiveLayout />
        </header>
        <ScrollToTop />
        <ToastContainer />
        <main className="main-content">
          <Routes>
            <Route element={<RoutesWithUserChatComponent />}>
              <Route path="/" element={<HomePage />} />
              {/* publicly available routes: */}
              <Route path="/cart" element={<CartPage />} />
              <Route path="/sign-in" element={<SignInPage />} />
              <Route
                path="request-reset"
                element={<RequestResetPasswordScreen />}
              />
              <Route path="/reset-password" element={<ResetPasswordScreen />} />
              <Route path="/login" element={<SignInPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route
                path="/product-details/:id"
                element={<ProductDetailsPage />}
              />
              <Route path="/men" element={<MenPage />} />
              <Route path="/suits" element={<MenPage />} />
              <Route path="/women" element={<WomenPage />} />
              <Route path="/bespoke" element={<BespokePage />} />
              <Route path="/textiles" element={<TextilePage />} />
              <Route path="/shop" element={<ShopPage />} />
              {/* attribute by category chosen */}
              {/* category and subcategory routes */}
              <Route
                path="/category/:categoryName"
                element={<CategoryPage />}
              />
              <Route path="/sub/:subName" element={<SubCategoryPage />} />
              <Route path="*" element="Page does not exist!" />
              {/* user protected routes: */}
              <Route element={<UserProtectedRoutes />}>
                <Route path="/user" element={<UserProfilePage />} />
                <Route path="/user/my-orders" element={<UserOrdersPage />} />
                <Route
                  path="/user/cart-details"
                  element={<UserCartDetailsPage />}
                />
                <Route
                  path="/user/order-details/:id"
                  element={<UserOrderDetailsPage />}
                />
              </Route>
            </Route>
            {/* admin protected routes: */}
            <Route element={<AdminProtectedRoutes />}>
              <Route path="/admin/users" element={<AdminUsersPage />} />
              <Route
                path="/admin/edit-user/:id"
                element={<AdminEditUserPage />}
              />
              <Route path="/admin/edit-user" element={<AdminEditUserPage />} />
              <Route path="/admin/products" element={<AdminProductsPage />} />
              <Route
                path="/admin/create-new-product"
                element={<AdminCreateProductPage />}
              />
              <Route
                path="/admin/sub-create"
                element={<AdminCreateSubsPage />}
              />
              <Route
                path="/admin/categories"
                element={<AdminCategoriesPage />}
              />
              <Route
                path="/admin/category-create"
                element={<AdminCreateCategoryPage />}
              />
              <Route
                path="/admin/category-edit/:id"
                element={<AdminEditCategoryPage />}
              />
              <Route
                path="/admin/sub-edit/:id"
                element={<AdminEditSubPage />}
              />
              <Route
                path="/admin/edit-product"
                element={<AdminEditProductPage />}
              />
              <Route
                path="/admin/edit-product/:id"
                element={<AdminEditProductPage />}
              />
              <Route path="/admin/subs" element={<AdminSubsPage />} />
              <Route path="/admin/orders" element={<AdminOrdersPage />} />

              <Route
                path="/admin/order-details/:id"
                element={<AdminOrderDetailsPage />}
              />

              <Route path="/admin/chats" element={<AdminChatsPage />} />
              <Route path="/admin/analytics" element={<AdminAnalyticsPage />} />
            </Route>
          </Routes>
        </main>

        <footer className="footer-custom">
          <FooterComponent />
        </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
