import * as actionTypes from '../../constants/products/cartConstants';
import axios from 'axios';

export const addToCartReduxAction =
  (id, quantity) => async (dispatch, getState) => {
    const { data } = await axios.get(`/api/products/get-one/${id}`);

    dispatch({
      type: actionTypes.ADD_TO_CART,
      payload: {
        productID: data._id,
        name: data.name,
        price: data.price,
        image: data.images[0] ?? null,
        count: data.count,
        quantity: parseFloat(quantity),
      },
    });

    localStorage.setItem('cart', JSON.stringify(getState().cart.cartItems));
  };
export const removeProductFromCartAction =
  (productID) => (dispatch, getState) => {
    dispatch({
      type: actionTypes.CLEAR_PRODUCT_FROM_CART,
      payload: productID,
    });

    // Update local storage to reflect the new state of the cart
    const updatedCartItems = getState().cart.cartItems;
    localStorage.setItem('cart', JSON.stringify(updatedCartItems));
  };
