import React from 'react';
import UserOrdersPageComponent from './components/UserOrdersPageComponent';
import axios from 'axios';
import { useSelector } from 'react-redux';

const fetchUserOrders = async () => {
  const { data } = await axios.get(`/api/orders`);
  return data;
};

const UserOrdersPage = () => {
  const { userInfo } = useSelector((state) => state.authUser);
  return (
    <UserOrdersPageComponent
      fetchUserOrders={fetchUserOrders}
      userInfo={userInfo}
    />
  );
};

export default UserOrdersPage;
