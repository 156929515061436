import React from 'react';
import { Card, Col } from 'react-bootstrap';

const ShippingDetailsCard = ({ userInfo, userAddress }) => {
  return (
    <Col
      md={6}
      className='shipping-details-card'
    >
      <Card>
        <Card.Header>
          <h3>Shipping Details</h3>
        </Card.Header>
        <Card.Body>
          {userInfo.name && (
            <Card.Text>
              <strong>Name:</strong> {userInfo.name}
            </Card.Text>
          )}
          {userInfo.lastName && (
            <Card.Text>
              <strong>Last name:</strong> {userInfo.lastName}
            </Card.Text>
          )}
          {userAddress.address && (
            <Card.Text>
              <strong>Address:</strong> {userAddress.address}
            </Card.Text>
          )}
          {userAddress.phoneNumber && (
            <Card.Text>
              <strong>Phone Number:</strong> {userAddress.phoneNumber}
            </Card.Text>
          )}
          {userAddress.country && (
            <Card.Text>
              <strong>Country:</strong> {userAddress.country}
            </Card.Text>
          )}
          {userAddress.postalCode && (
            <Card.Text>
              <strong>Postal Code:</strong> {userAddress.postalCode}
            </Card.Text>
          )}
          {userAddress.city && (
            <Card.Text>
              <strong>City:</strong> {userAddress.city}
            </Card.Text>
          )}
          {userAddress.state && (
            <Card.Text>
              <strong>State:</strong> {userAddress.state}
            </Card.Text>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ShippingDetailsCard;
