import axios from "axios";
import React from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";

const UploadImages = ({ images, setImages, isLoading, setIsLoading }) => {
  const fileUploadAndResize = (e) => {
    const files = e.target.files;
    const existingImages = [...images];

    if (files) {
      setIsLoading(true);
      for (let i = 0; i < files.length; i++) {
        Resizer.imageFileResizer(
          files[i],
          720,
          720,
          "JPEG",
          100, // Reduce quality to compress further
          0,
          async (uri) => {
            try {
              // Create FormData and append the image data
              const formData = new FormData();
              formData.append("image", uri);
              console.log(uri);
              // Upload the image to the backend
              const response = await axios.post(
                "/api/uploader/upload-images",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );

              existingImages.push({
                public_id: response.data.public_id,
                uri: response.data.uri,
                file: dataURItoFile(uri, files[i].name),
              });

              setImages(existingImages);
              setIsLoading(false);
            } catch (error) {
              setIsLoading(false);
              const errorMessage =
                error.response &&
                error.response.data &&
                error.response.data.message
                  ? error.response.data.message
                  : "Unknown error occurred!";
              toast.error(errorMessage);
            }
          },
          "base64"
        );
      }
    }
  };

  const dataURItoFile = (dataURI, fileName) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([ab], fileName, { type: mimeString });
  };

  const handleImageRemove = (public_id) => {
    const updatedImages = images.filter(
      (image) => image.public_id !== public_id
    );
    setImages(updatedImages);
  };

  return (
    <Row>
      <Form.Group controlId="formFileMultiple" className="mb-3 mt-3">
        <Form.Label>Images</Form.Label>
        <Form.Control
          className="mb-3"
          type="file"
          multiple
          onChange={fileUploadAndResize}
        />
        <Row>
          {images &&
            images.map((image, idx) => (
              <Col key={idx} style={{ position: "relative" }} xs={3}>
                <Image src={image.uri} crossOrigin="anonymous" fluid />
                <i
                  className="bi bi-x text-danger"
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    left: "5px",
                    top: "-10px",
                    transform: "scale(2.7)",
                  }}
                  onClick={() => handleImageRemove(image.public_id)}
                ></i>
              </Col>
            ))}
        </Row>
      </Form.Group>
    </Row>
  );
};

export default UploadImages;
