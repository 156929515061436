import * as actionTypes from "../../constants/auth/userConstants";

const INITIAL_STATE = {
  userInfo: null,
  error: null,
  isLoading: false,
};

export const authUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.USER_RESET_REQUEST:
      return { ...state, isLoading: true };
    case actionTypes.USER_RESET_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        error: null,
        isLoading: false,
      };
    case actionTypes.USER_RESET_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case actionTypes.LOGIN_USER:
      return {
        ...state,
        userInfo: action.payload,
        error: null,
        isLoading: false,
      };
    case actionTypes.REGISTER_USER:
      return {
        ...state,
        userInfo: action.payload,
        error: null,
        isLoading: false,
      };
    case actionTypes.USER_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case actionTypes.USER_UPDATE_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        isLoading: false,
        error: null,
      };
    case actionTypes.USER_UPDATE_FAIL:
      return { ...state, isLoading: false, error: action.payload };
    case actionTypes.USER_LOGIN_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        isLoading: false,
        error: null,
      };
    case actionTypes.LOGOUT_USER:
      return {
        ...state,
        userInfo: null,
        isLoading: false,
        error: null,
      };
    case actionTypes.REGISTER_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case actionTypes.LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
