import React from "react";
import { Link } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../../home-products.css"; // Ensure you have your CSS here

const HomeCollage = ({ styleVersion }) => {
  const { products } = useSelector((state) => state.getProducts);
  const collageClass = `${styleVersion}-collage`;
  const cardClass = `product-for-list-card ${styleVersion}-card`;
  const bodyClass = `product-for-list-card-body ${styleVersion}-body`;
  const titleClass = `card-title ${styleVersion}-title`;
  const buttonClass = `${styleVersion}-button`;
  console.log("home collage products", products);

  return (
    <div className={collageClass}>
      <h1 className="text-info">LOOKS CURATED BY ACADEMY</h1>
      {products &&
        products.map((product) => (
          <Card className={cardClass} key={product._id}>
            <Card.Img
              crossOrigin="anonymous"
              variant="top"
              className="card-img-top"
              src={product.images[0] ? product.images[0].uri : ""}
            />
            <Card.Body className={bodyClass}>
              <Card.Title className={titleClass}>{product.name}</Card.Title>
              <Link to={`/product-details/${product._id}`}>
                <Button className={buttonClass}>See product</Button>
              </Link>
            </Card.Body>
          </Card>
        ))}
    </div>
  );
};

export default HomeCollage;
