import React, { useEffect, useState } from "react";
import { Container, Navbar, Button, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import SearchForm from "../../components/form/SearchForm";
import CategoryNav from "./CategoryNav";
import Sidebar from "./Sidebar";
import NavLinks from "./NavLinks";
import "../../css/header.css";
const HeaderComponent = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSidebarShow = () => setShowSidebar(true);
  const handleSidebarClose = () => setShowSidebar(false);

  return (
    <>
      <Navbar expand="lg" className=" shadow-sm sticky-top main-header-navbar">
        <Container fluid className="d-flex justify-between items-center">
          {/* Brand */}
          <Navbar.Brand as={Link} to="/" className="text-2xl font-bold">
            <h1>Academy London</h1>
            <p>Ready-Made</p>
          </Navbar.Brand>

          {/* Search Form */}
          {windowWidth >= 768 && (
            <div className="flex-grow mx-2">
              <SearchForm />
            </div>
          )}

          {/* Icons and Sidebar Toggle */}
          <div className="flex items-center">
            {windowWidth >= 768 ? (
              <NavLinks />
            ) : (
              <Button
                variant="outline-secondary"
                onClick={handleSidebarShow}
                className="ms-auto"
              >
                ☰
              </Button>
            )}
          </div>
        </Container>
      </Navbar>

      {/* CategoryNav for Large Screens */}
      {windowWidth >= 768 && (
        <Nav className="bg-light">
          <Container>
            <CategoryNav />
          </Container>
        </Nav>
      )}

      {/* Sidebar for Small Screens */}
      <Sidebar show={showSidebar} handleClose={handleSidebarClose} />
    </>
  );
};

export default HeaderComponent;
