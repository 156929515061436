import React from "react";
import { Container, Row } from "react-bootstrap";

import MetaComponent from "../../components/meta/MetaComponent";
import HomeCategoryCard from "../../components/homepage/HomeCategoryCard";
import HomeProductCarousel from "../../components/homepage/HomeProductCarousel";
import HomeCollage from "../../components/homepage/HomeCollage";

const HomePageComponent = ({ products }) => {
  return (
    <>
      <MetaComponent
        title="Academy London Ready-Made"
        description="Made in London with love"
      />

      <Container fluid>
        <Row className="mb-4 mt-4">
          <HomeCategoryCard />
        </Row>
        <Row className="mb-4 mt-4">
          <HomeProductCarousel products={products} />
        </Row>
        <Row>
          {/* You can change "retro" to "polaroid", "postcard", "newspaper", "artdeco", or "filmreel" */}
          <HomeCollage styleVersion="artdeco" />
        </Row>
      </Container>
    </>
  );
};

export default HomePageComponent;
