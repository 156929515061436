import React from 'react';
import AdminOrdersPageComponent from './components/AdminOrdersPageComponent';
import axios from 'axios';

const AdminOrdersPage = () => {
  const listOrders = async (controller) => {
    const { data } = await axios.get('/api/orders/admin', {
      signal: controller.signal,
    });

    return data;
  };
  return <AdminOrdersPageComponent listOrders={listOrders} />;
};

export default AdminOrdersPage;
