import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";

import AddAttributes from "../../../components/product/AddAttributes";
import SubCategorySelector from "../../../components/product/SubCategorySelector";
import AttributeSelectors from "../../../components/product/AttributeSelectors";
import ProductAttributes from "../../../components/product/ProductAttributes";
import CategorySelector from "../../../components/product/CategorySelector";

import UploadImages from "../../../components/product/UploadImages";

const AdminEditProductPageComponent = ({
  categories,
  fetchProduct,
  subs,
  updateProduct,
  saveAttributeToCatDoc,
  reduxDispatch,
  deleteCategoryReduxAction,
}) => {
  const [validated, setValidated] = useState(false);
  const [product, setProduct] = useState({});
  const [categoryChosen, setCategoryChosen] = useState("");
  const [selectedSubs, setSelectedSubs] = useState([]);

  const [attributesFromDatabase, setAttributesFromDatabase] = useState([]);
  const [attributesTable, setAttributesTable] = useState([]);

  const [newAttributeKey, setNewAttributeKey] = useState("");
  const [newAttributeValue, setNewAttributeValue] = useState([]);
  const [attributeValue, setAttributeValue] = useState([]);
  const [attributeKey, setAttributeKey] = useState("");
  const [images, setImages] = useState([]); // Correctly initializes as an array
  const [isLoading, setIsLoading] = useState(false);
  //images
  const [imageRemoved, setImageRemoved] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProduct(id)
      .then((res) => {
        if (res) {
          setProduct(res);
          setCategoryChosen(res.category ?? "");
          if (res.images && res.images.length > 0) {
            setImages(res.images);
          } else {
            setImages([]);
          }

          // Assuming the product object has an array of subcategory IDs in a property `subcategories`
          if (subs) {
            const relatedSubs = subs.filter((s) => s.parentId === res.category);

            // Now filter to include only subs that are also part of the product
            const productSubIds = relatedSubs
              .filter((s) => res.subs.includes(s._id)) // Check if subcategory is part of the product
              .map((sub) => sub._id);

            setSelectedSubs(productSubIds);
          }

          const categoryOfFetchedProduct =
            categories &&
            categories.find((category) => category._id === res.category);
          if (
            categoryOfFetchedProduct &&
            categoryOfFetchedProduct.attrs.length > 0
          ) {
            setAttributesFromDatabase(categoryOfFetchedProduct.attrs);
          }

          setAttributesTable(res.attrs);
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Unknown error occurred!";
        toast.error(errorMessage);
      });
  }, [id, fetchProduct, subs, categories]);

  const handleCategoryChange = (e) => {
    const newCategory = e.target.value;

    // Reset subcategories and attributes when changing categories
    if (newCategory && newCategory !== "") {
      // Filter subcategories by the selected category
      setCategoryChosen(newCategory);

      const filteredSubs = subs.filter((s) => s.parentId === newCategory);

      const updatedSelectedSubs = filteredSubs
        .filter((sub) => product.subs.includes(sub._id))
        .map((sub) => sub._id);
      setSelectedSubs(updatedSelectedSubs);
      // Find and set attributes from the chosen category
      const categoryAttributes =
        categories.find((c) => c._id === newCategory)?.attrs || [];
      setAttributesFromDatabase(categoryAttributes);
    } else {
      setSelectedSubs([]);
      setAttributesFromDatabase([]);
    }
  };

  const handleSubCategoryChange = (e) => {
    const subId = e.target.value;
    const isChecked = e.target.checked;

    // Update the selected subs based on checkbox state
    setSelectedSubs((prevSubs) => {
      if (isChecked) {
        return [...prevSubs, subId];
      } else {
        return prevSubs.filter((s) => s !== subId);
      }
    });
  };

  const handleNewAttributeKey = (e) => {
    const newAttrKey = e.target.value;

    setNewAttributeKey(newAttrKey);
  };
  const handleNewAttributeValue = (e) => {
    if (newAttributeKey) {
      const newAttrValue = e.target.value;
      setNewAttributeValue(newAttrValue);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    const formData = new FormData();
    formData.append("name", form.elements["name"].value);
    formData.append("description", form.elements["description"].value);
    formData.append("count", form.elements["count"].value);
    formData.append("price", form.elements["price"].value);
    formData.append("category", form.elements["category"].value);
    formData.append("images", JSON.stringify(images));
    // Append subcategories
    selectedSubs.forEach((subId) => {
      formData.append("subs", subId); // Note the use of 'subs' to signify an array to the backend
    });

    // Debugging: Console log the attributesTable to check its format

    formData.append("attrs", JSON.stringify(attributesTable));

    if (form.checkValidity() === true) {
      updateProduct(id, formData) // parse form data and id
        .then((res) => {
          toast.success(res.message);
          navigate("/admin/products");
        })
        .catch((error) => {
          const errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Unknown error occurred!";
          toast.error(errorMessage);
        });
    }

    setValidated(true);
  };

  const handleAddAttrsAndSaveToCategoryDocument = () => {
    // Check if a category is chosen
    if (!categoryChosen) {
      toast.error("Please select a category before adding attributes.");
      return;
    }

    // Check if both key and value for the new attribute are provided
    if (!newAttributeKey || !newAttributeValue) {
      toast.error("Please enter both an attribute key and a value.");
      return;
    }

    // Find if the attribute key already exists in the table
    const existingAttributeIndex = attributesTable.findIndex(
      (attr) => attr.key === newAttributeKey
    );

    let isNewAttribute = existingAttributeIndex === -1;
    let updatedValues = [];

    if (isNewAttribute) {
      // Attribute does not exist, add it to the table with the new value in an array
      attributesTable.push({
        key: newAttributeKey,
        value: [newAttributeValue],
      });
      updatedValues = [newAttributeValue];
    } else {
      // Attribute exists, add new value if it's not already included
      let attribute = attributesTable[existingAttributeIndex];
      let valueSet = new Set([...attribute.value, newAttributeValue]);
      updatedValues = Array.from(valueSet);

      // Update the specific attribute's values
      attributesTable[existingAttributeIndex] = {
        ...attribute,
        value: updatedValues,
      };
    }

    // Update the state to reflect changes
    setAttributesTable([...attributesTable]);
    toast.success("Attribute added successfully.");

    // Dispatch the saveAttributeToCatDoc action
    reduxDispatch(
      saveAttributeToCatDoc(newAttributeKey, updatedValues, categoryChosen)
    );

    // Optionally clear input fields after adding
    setNewAttributeKey("");
    setNewAttributeValue("");
  };
  const addAttributeToTable = () => {
    if (!categoryChosen) {
      toast.error("Please select a category.");
      return;
    }
    if (!attributeKey) {
      toast.error("Please select an attribute key.");
      return;
    }
    if (attributeValue.every((item) => !item.checked)) {
      toast.error("Please select at least one value for the attribute.");
      return;
    }

    // Filter out only checked values and map to their 'value' property
    const selectedValues = attributeValue
      .filter((item) => item.checked)
      .map((item) => item.value);

    // Check if the attribute already exists in the table
    const existingAttribute = attributesTable.find(
      (attr) => attr.key === attributeKey
    );
    if (existingAttribute) {
      // Merge new values with existing ones ensuring uniqueness
      const updatedValues = Array.from(
        new Set([...existingAttribute.value, ...selectedValues])
      );
      setAttributesTable(
        attributesTable.map((attr) =>
          attr.key === attributeKey ? { ...attr, value: updatedValues } : attr
        )
      );
    } else {
      // Add new attribute to the table
      setAttributesTable([
        ...attributesTable,
        { key: attributeKey, value: selectedValues },
      ]);
    }

    toast.success("Attribute added successfully.");
  };

  const handleAttributeKeyChange = (e) => {
    const selectedKey = e.target.value;
    setAttributeKey(selectedKey);

    const relatedAttrs = attributesFromDatabase.find(
      (attr) => attr.key === selectedKey
    );
    if (relatedAttrs) {
      const checkboxValues = relatedAttrs.value.map((value) => ({
        value,
        checked: false,
      }));
      setAttributeValue(checkboxValues);
    } else {
      setAttributeValue([]);
    }
  };

  const handleAttributeValueChange = (e) => {
    const { value, checked } = e.target;
    setAttributeValue((prevValues) =>
      prevValues.map((item) =>
        item.value === value ? { ...item, checked } : item
      )
    );
  };
  const handleDeleteAttribute = (key) => {
    setAttributesTable((prevTable) =>
      prevTable.filter((item) => item.key !== key)
    );
  };

  const handleDeleteCategoryHandler = () => {
    const element = document.getElementById("categoryChosen");
    if (element && element.value) {
      reduxDispatch(
        deleteCategoryReduxAction(
          element.value,
          () => {
            toast.success("Category deleted successfully");
          },
          (errorMessage) => {
            toast.error(`Error deleting category: ${errorMessage}`);
          }
        )
      );
    } else {
      toast.error("Invalid category selection");
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col md={3}>
          <Link to="/admin/products" className="btn btn-info my-3">
            Go Back
          </Link>
          <AdminLinksComponent />
        </Col>

        <Col md={9}>
          <h1>Edit product</h1>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                required
                type="text"
                defaultValue={product.name}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                name="description"
                required
                as="textarea"
                rows={3}
                defaultValue={product.description}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCount">
              <Form.Label>Count in stock</Form.Label>
              <Form.Control
                name="count"
                required
                type="number"
                defaultValue={product.count}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPrice">
              <Form.Label>Price</Form.Label>
              <Form.Control
                name="price"
                required
                type="text"
                defaultValue={product.price}
              />
            </Form.Group>
            <CategorySelector
              categories={categories}
              categoryChosen={categoryChosen}
              handleCategoryChange={handleCategoryChange}
              handleDeleteCategoryHandler={handleDeleteCategoryHandler}
            />

            <SubCategorySelector
              subs={subs}
              categoryChosen={categoryChosen}
              selectedSubs={selectedSubs}
              handleSubCategoryChange={handleSubCategoryChange}
            />

            <AttributeSelectors
              attributeKey={attributeKey}
              attributeValue={attributeValue}
              handleAttributeKeyChange={handleAttributeKeyChange}
              handleAttributeValueChange={handleAttributeValueChange}
              categoryChosen={categoryChosen}
              attributesFromDatabase={attributesFromDatabase}
              addAttributeToTable={addAttributeToTable}
            />

            <ProductAttributes
              attributesTable={attributesTable}
              handleDeleteAttribute={handleDeleteAttribute}
            />

            {categoryChosen && (
              <AddAttributes
                newAttributeKey={newAttributeKey}
                newAttributeValue={newAttributeValue}
                handleNewAttributeKey={handleNewAttributeKey}
                handleNewAttributeValue={handleNewAttributeValue}
                handleAddAttrsAndSaveToCategoryDocument={
                  handleAddAttrsAndSaveToCategoryDocument
                }
              />
            )}
            <UploadImages
              images={images}
              setImages={setImages}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
            <Button variant="primary" type="submit">
              UPDATE
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminEditProductPageComponent;
