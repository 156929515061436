import React from 'react';
import AdminEditUserPageComponent from './components/AdminEditUserPageComponent';
import axios from 'axios';

const adminUpdateUser = async (id, name, lastName, email, isAdmin) => {
  const { data } = await axios.put(`/api/users/admin/update-user/${id}`, {
    name,
    lastName,
    email,
    isAdmin,
  });
  return data;
};

const adminFetchUser = async (id) => {
  const { data } = await axios.get(`/api/users/admin/get-one/user/${id}`);
  return data;
};
const AdminEditUserPage = () => {
  return (
    <AdminEditUserPageComponent
      adminUpdateUser={adminUpdateUser}
      adminFetchUser={adminFetchUser}
    />
  );
};

export default AdminEditUserPage;
