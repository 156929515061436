import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../css/search-form.css";
import { Button, Form } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

const SearchForm = ({ className }) => {
  const dispatch = useDispatch();
  const { text } = useSelector((state) => state.search);
  const navigate = useNavigate();

  const handleChange = (e) => {
    dispatch({
      type: "SEARCH_QUERY",
      payload: { text: e.target.value },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(`/shop?${text}`);
  };

  return (
    <Form onSubmit={handleSubmit} className="search-form-custom">
      <Form.Control
        type="search"
        placeholder="Search..."
        className="search-input-premium"
        aria-label="Search"
        onChange={handleChange}
      />
      <Button type="submit" className="btn-search">
        <FaSearch size={24} />
      </Button>
    </Form>
  );
};

export default SearchForm;
