import React from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import AdminCreateProductPageComponent from './components/AdminCreateProductPageComponent';
import {
  deleteCategoryReduxAction,
  insertNewCategoryReduxAction,
  saveAttributeToCatDoc,
} from '../../redux/actions/category/categoryActions';
import {
  deleteSubcategoryReduxAction,
  insertNewSubReduxAction,
} from '../../redux/actions/subs/subActions';
import { createProductReduxAction } from '../../redux/actions/product/product';

const AdminCreateProductPage = () => {
  const dispatch = useDispatch();
  const reduxDispatch = dispatch;
  const { categories } = useSelector((state) => state.getCategories);

  const { subs } = useSelector((state) => state.getSubs);
  return (
    <AdminCreateProductPageComponent
      categories={categories}
      subs={subs}
      reduxDispatch={reduxDispatch}
      insertNewCategoryReduxAction={insertNewCategoryReduxAction}
      insertNewSubReduxAction={insertNewSubReduxAction}
      deleteCategoryReduxAction={deleteCategoryReduxAction}
      deleteSubcategoryReduxAction={deleteSubcategoryReduxAction}
      createProductReduxAction={createProductReduxAction}
      saveAttributeToCatDoc={saveAttributeToCatDoc}
    />
  );
};

export default AdminCreateProductPage;
