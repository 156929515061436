import React, { useEffect, useRef, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
} from 'react-bootstrap';
import CartItemComponent from '../../../components/cart/CartItemComponent';
import { toast } from 'react-toastify';
import ShippingDetailsCard from '../../../components/user/ShippingDetailsComponent';
import { useParams } from 'react-router-dom';

const UserOrderDetailsPageComponent = ({
  userInfo,
  fetchUserOrder,
  fetchUser,
  loadPayPalScript,
}) => {
  const paypalContainer = useRef();
  const [cartItems, setCartItems] = useState([]);
  const [userAddress, setUserAddress] = useState({});
  const { id } = useParams();
  const [paymentMethod, setPaymentMethod] = useState('');
  const [isPaid, setIsPaid] = useState(false);
  const [isDelivered, setIsDelivered] = useState(false);
  const [orderButtonMessage, setOrderButtonMessage] = useState(false);
  const [cartSubtotal, setCartSubtotal] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  //fetch user order
  useEffect(() => {
    fetchUserOrder(id)
      .then((res) => {
        setPaymentMethod(res.data.paymentMethod);
        setCartItems(res.data.cartItems);

        setCartSubtotal(res.data.orderTotal.cartSubtotal);
        res.data.isPaid ? setIsPaid(res.data.paidAt) : setIsPaid(false);
        res.data.isDelivered
          ? setIsDelivered(res.data.deliveredAt)
          : setIsDelivered(false);
        if (res.data.isPaid) {
          setOrderButtonMessage(
            'We are delighted to confirm your payment. Thank you for choosing us!'
          );
          setButtonDisabled(true);
        } else {
          setOrderButtonMessage('Proceed to payment.');

          setButtonDisabled(false);
        }
      })
      .catch((error) => {
        const errorMessage =
          error.res && error.res.data && error.res.data.message
            ? error.res.data.message
            : error.response.data || 'Unknown error occurred!'; // Added error.message to catch other potential messages

        toast.error(errorMessage);
      });
  }, [fetchUserOrder, userInfo._id, id]);
  //fetch user info
  useEffect(() => {
    fetchUser(userInfo._id)
      .then((res) => {
        setUserAddress({
          address: res.address,
          phoneNumber: res.phoneNumber,
          country: res.country,
          postalCode: res.postalCode,
          city: res.city,
          state: res.state,
        });
      })
      .catch((error) => {
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.response.data || 'Unknown error occurred!'; // Added error.message to catch other potential messages

        toast.error(errorMessage);
      });
  }, [fetchUser, userInfo._id]);

  const orderHandler = async () => {
    setButtonDisabled(true);

    if (paymentMethod === 'pp') {
      setOrderButtonMessage(
        'Please select a payment method below to complete your order'
      );
      if (!isPaid) {
        //to do: load paypal script
        await loadPayPalScript(
          cartSubtotal,
          cartItems,
          id,
          updateStateAfterSuccessfulPayment
        );
      }
    } else {
      setOrderButtonMessage(
        'Your order has been successful. Thank you for choosing us!'
      );
    }
  };
  const updateStateAfterSuccessfulPayment = (paidAt) => {
    setOrderButtonMessage(
      'Your payment has been successful. Thank you for choosing us!'
    );
    setIsPaid(paidAt);
    setButtonDisabled(true);
    paypalContainer.current.style = 'display: none';
  };

  return (
    <Container>
      <Row>
        <h1 className='text-centre text-success'>Order Details</h1>
        <Col md={8}>
          <Row>
            <ShippingDetailsCard
              userInfo={userInfo}
              userAddress={userAddress}
            />

            <Col md={6}>
              <h3>Payment Method</h3>
              <Form.Select
                value={paymentMethod}
                disabled={true}
              >
                <option value='pp'>Paypal</option>
                <option value='stripe'>Stripe</option>
              </Form.Select>
            </Col>
            <Row>
              <Col>
                <Alert
                  variant={isDelivered ? 'success' : 'danger'}
                  className='mt-3'
                >
                  {isDelivered ? (
                    <p>Delivered at {isDelivered}</p>
                  ) : (
                    <p>Delivery pending</p>
                  )}
                </Alert>
              </Col>
              <Col>
                <Alert
                  className='mt-3'
                  variant={isPaid ? 'success' : 'danger'}
                >
                  {isPaid ? <p>Paid at {isPaid} </p> : <p>Awaiting payment</p>}
                </Alert>
              </Col>
            </Row>
          </Row>
          <br />
        </Col>

        <Col md={4}>
          <ListGroup>
            <ListGroup.Item>
              <h3>Order Summary</h3>
            </ListGroup.Item>
            <ListGroup variant='flush'>
              {cartItems &&
                cartItems.map((item, index) => (
                  <ListGroup.Item key={index}>
                    <Row>
                      <Col>
                        <p>{item.name}</p>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
            </ListGroup>
            <ListGroup.Item>
              Items price (after tax):{' '}
              <span className='fw-bold'>£ {cartSubtotal}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              Shipping: <span className='fw-bold'>Included</span>
            </ListGroup.Item>
            <ListGroup.Item>
              Tax: <span className='fw-bold'>Included</span>
            </ListGroup.Item>
            <ListGroup.Item className='text-danger'>
              Total price: <span className='fw-bold'>£ {cartSubtotal}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className='d-grid gap-2'>
                <Button
                  size='lg'
                  variant={isPaid ? 'success' : 'primary'}
                  type='submit'
                  disabled={buttonDisabled}
                  onClick={orderHandler}
                >
                  {orderButtonMessage}
                </Button>
              </div>
              <div style={{ position: 'relative', zIndex: 10 }}>
                <div
                  ref={paypalContainer}
                  id='paypal-container-element'
                ></div>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      <Row></Row>
    </Container>
  );
};

export default UserOrderDetailsPageComponent;
