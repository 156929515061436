import React from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../css/product-for-list.css"; // Import the CSS file

const ProductForListComponent = ({ product }) => {
  // Destructure the properties, with default values to avoid undefined errors
  const { images = [], name = "No name", price = 0 } = product || {};

  const locale = "en-GB"; // This can be dynamic based on user preferences or location
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "GBP",
  });
  const formattedPrice = formatter.format(price);

  // Define a fallback image URL
  const fallbackImageUrl = "path/to/fallback/image.jpg";

  return (
    <Card className="product-for-list-card">
      <Card.Img
        crossOrigin="anonymous"
        variant="top"
        className="card-img-top"
        src={images && images[0] ? images[0].uri : fallbackImageUrl}
        alt={name}
      />
      <Card.Body className="product-for-list-card-body">
        <Card.Title className="card-title">
          <h3>{name}</h3>
        </Card.Title>
        <Card.Text className="card-text">
          <span className="pound-sign">£</span>
          <span className="price-value">{price}</span>
        </Card.Text>
        <Button className="btn button retro-button">
          <Link to={`/product-details/${product._id}`}>See product</Link>
        </Button>
      </Card.Body>
    </Card>
  );
};

export default ProductForListComponent;
