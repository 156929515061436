import * as actionType from '../../constants/category/categoryConstant';

export const getCategoriesReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case actionType.GET_CATEGORIES:
      return {
        ...state,
        categories: Array.isArray(action.payload) ? action.payload : [],
      };
    case actionType.CREATE_CATEGORY:
      return { ...state, categories: [...state.categories, action.payload] };

    case actionType.INSERT_NEW_CATEGORY:
      // return { ...state, categories: [...action.payload] };
      // Directly set categories to the payload which already includes the new category
      return { ...state, categories: action.payload };

    case actionType.SAVE_ATTR:
      return {
        ...state,
        categories: state.categories.map((category) =>
          category._id === action.payload.categoryId
            ? { ...category, attrs: action.payload.attrs }
            : category
        ),
      };
    case actionType.EDIT_CATEGORY: {
      return {
        ...state,
        categories: state.categories.map((category) =>
          category._id === action.payload.category ? action.payload : category
        ),
      };
    }
    case actionType.DELETE_NEW_CATEGORY:
      return {
        // Filter out the category by ID and return the new array
        ...state,
        categories: state.categories.filter(
          (category) => category._id !== action.payload
        ),
      };
    default:
      return state;
  }
};
