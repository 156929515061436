import React from "react";

import HomePageComponent from "./components/HomePageComponent";
import { useSelector } from "react-redux";
const HomePage = () => {
  const { products } = useSelector((state) => state.getProducts);

  return (
    <>
      <HomePageComponent products={products} />;
    </>
  );
};

export default HomePage;
