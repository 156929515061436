import React from 'react';
import AdminOrderDetailsPageComponent from './components/AdminOrderDetailsPageComponent';
import axios from 'axios';
import { logoutUserReduxAction } from '../../redux/actions/auth/userActions';

const fetchOrder = async (orderId) => {
  const { data } = await axios.get('/api/orders/user/' + orderId);
  return data;
};

const markAsDelivered = async (orderId) => {
  const { data } = await axios.put(`/api/orders/delivered/${orderId}`);
  if (data) {
    return data;
  }
};

const AdminOrderDetailsPage = () => {
  return (
    <AdminOrderDetailsPageComponent
      fetchOrder={fetchOrder}
      markAsDelivered={markAsDelivered}
      logoutUserReduxAction={logoutUserReduxAction}
    />
  );
};

export default AdminOrderDetailsPage;
