import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import UploadImages from "../../../components/product/UploadImages";
const AdminEditSubPageComponent = ({ updateSub, categories, fetchSub }) => {
  const [name, setName] = useState("");
  const [parentId, setParentId] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchSub(id)
      .then((res) => {
        setName(res.name);
        setParentId(res.parentId);

        if (res.images && res.images.length > 0) {
          setImages(res.images);
        } else {
          setImages([]);
        }
        setDescription(res.description);
      })
      .catch((error) => {
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Unknown error occurred!";
        toast.error(errorMessage);
      });
  }, [fetchSub, id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("parentId", parentId);
    formData.append("images", JSON.stringify(images));

    updateSub(id, formData)
      .then((res) => {
        toast.success(res.message);
        navigate("/admin/subs");
      })
      .catch((error) => {
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Unknown error occurred!";
        toast.error(errorMessage);
      });
  };
  return (
    <Container>
      <Row className="justify-content-md-center mt-4">
        <Col md={6}>
          <h1>Edit Sub</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                required
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                required
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Parent Category</Form.Label>
              <select
                name="parentId"
                className="form-control"
                required // Ensure this is added to enforce selection
                value={parentId}
                onChange={(e) => setParentId(e.target.value)}
              >
                <option value="">Select a category</option>
                {categories &&
                  categories.map((category, idx) => (
                    <option value={category._id} key={idx}>
                      {category.name}
                    </option>
                  ))}
              </select>
            </Form.Group>

            <UploadImages
              images={images}
              setImages={setImages}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />

            <Form.Group>
              <Form.Label />
              <div className="d-grid gap-3">
                <Button variant="primary" type="submit" className="btn btn-lg">
                  Update
                </Button>
              </div>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminEditSubPageComponent;
