import React from "react";
import Select from "react-select";

/**
 * AttributeFilter handles displaying and managing selections for product attributes
 * such as size, color, etc. Each attribute has its own dropdown menu.
 *
 * @param {Object[]} attributes - An array of attribute objects with key and values.
 * @param {Function} onAttrChange - Callback function to handle changes to attribute selections.
 */

const AttributeFilter = ({ attributes, selectedAttrs, onAttrChange }) => {
  return (
    <>
      {attributes.map((attr, index) => (
        <div className="filter-group mt-3" key={index}>
          <label htmlFor={`attr-select-${index}`} className="mb-2">
            <span>
              <h4>{attr.key}</h4>
            </span>
          </label>
          <Select
            id={`attr-select-${index}`}
            options={attr.values.map((value) => ({
              value: value,
              label: value,
            }))}
            value={
              selectedAttrs[attr.key]
                ? {
                    value: selectedAttrs[attr.key],
                    label: selectedAttrs[attr.key],
                  }
                : null
            }
            onChange={(selected) =>
              onAttrChange(attr.key, selected ? selected.value : null)
            }
            placeholder={`Select ${attr.key}`}
            isClearable
            classNamePrefix="attribute-select"
          />
        </div>
      ))}
    </>
  );
};

export default AttributeFilter;
