import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";

import axios from "axios";
import { toast } from "react-toastify";

const AdminOrdersPageComponent = ({ listOrders }) => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    try {
      listOrders(controller)
        .then((res) => {
          setOrders(res);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log("Failed to list orders");
          } else {
            console.log("Failed to list orders");
          }
        });
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Unknown error occurred!";
      toast.error(errorMessage);
    }

    return () => {
      controller.abort();
    };
  }, [listOrders]);

  return (
    <Container>
      <Row className="mt-5 ">
        <Col md={2}>
          <AdminLinksComponent />
        </Col>
        <Col md={10}>
          <h1 className="text-primary">Orders</h1>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>User</th>
                <th>Date</th>
                <th>Total</th>
                <th>Is Paid</th>
                <th>Delivered</th>
                <th>Payment Method</th>
                <th>Order Details</th>
              </tr>
            </thead>

            <tbody>
              {orders.map((order, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{order.user ? order.user.name : ""}</td>
                  <td>{order.createdAt}</td>
                  <td>{order.orderTotal.cartSubtotal}</td>
                  <td>
                    {order.isDelivered ? (
                      <i className="bi bi-check-lg text-success"></i>
                    ) : (
                      <i className="bi bi-x-lg text-danger"></i>
                    )}
                  </td>
                  <td>
                    {order.isPaid ? (
                      <i className="bi bi-check-lg text-success"></i>
                    ) : (
                      <i className="bi bi-x-lg text-danger"></i>
                    )}
                  </td>
                  <td>{order.paymentMethod}</td>

                  <td>
                    <Link to={`/admin/order-details/${order._id}`}>
                      go to order
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminOrdersPageComponent;
