export const Auth_REQUEST = "AUTH_REQUEST";

export const LOGIN_USER = "LOGIN_USER";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const USER_RESET_REQUEST = "USER_RESET_REQUEST";
export const USER_RESET_SUCCESS = "USER_RESET_SUCCESS";
export const USER_RESET_FAIL = "USER_RESET_FAIL";
