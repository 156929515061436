import * as actionType from "../../constants/subs/subConstants";

export const getSubsReducer = (state = [], action) => {
  switch (action.type) {
    case actionType.GET_SUBS:
      return { ...state, subs: action.payload };
    case actionType.INSERT_NEW_SUB_CATEGORY:
      return { ...state, subs: [...action.payload] };
    case actionType.CREATE_SUB_CATEGORY:
      return { ...state, subs: [...state.subs, action.payload] };
    case actionType.DELETE_NEW_SUB:
      return { ...state, subs: action.payload };
    default:
      return state;
  }
};
