import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const ResetPasswordScreen = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const resetToken = new URLSearchParams(window.location.search).get("token");

  const validatePassword = (password) => {
    const minLength = 8;
    const hasNumber = /\d/;
    const hasLowercase = /[a-z]/;
    const hasUppercase = /[A-Z]/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    if (password.length < minLength) {
      return toast.error(
        `Password must be at least ${minLength} characters long.`
      );
    }
    if (!hasNumber.test(password)) {
      return toast.error("Password must contain at least one number.");
    }
    if (!hasLowercase.test(password)) {
      return toast.error(
        "Password must contain at least one lowercase letter."
      );
    }
    if (!hasUppercase.test(password)) {
      return toast.error(
        "Password must contain at least one uppercase letter."
      );
    }
    if (!hasSpecialChar.test(password)) {
      return toast.error(
        "Password must contain at least one special character."
      );
    }
    return null;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const validationError = validatePassword(password);
    if (validationError) {
      setMessage(validationError);

      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");

      return;
    }

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `/api/users/reset-password`,
        {
          token: resetToken,
          password,
        },
        config
      );

      toast.success(data.message);
      navigate("/sign-in");
    } catch (error) {
      toast.error("Failed to reset the password. Please try again.");
    }
  };

  return (
    <>
      <Container className="auth-container">
        <Row className="mt-3 sign-in-form">
          <Col md={6}>
            s{/* {message && <p>{message}</p>} */}
            <Form onSubmit={submitHandler} className="mt-4 mb-4">
              <h4>Reset Your Password</h4>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="password">New Password</Form.Label>
                <Form.Control
                  type="password"
                  id="password"
                  placeholder="Enter new password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="confirmPassword">
                  Confirm Password:
                </Form.Label>
                <Form.Control
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label />
                <Button type="submit">Reset Password</Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ResetPasswordScreen;
