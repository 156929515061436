import React, { useEffect } from 'react';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
const AddedToCartMessageComponent = ({
  showCartMessage,
  setShowCartMessage,
}) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (showCartMessage) {
      const timer = setTimeout(() => {
        setShowCartMessage(false);
      }, 2000); // Set the timeout to 2000 milliseconds (2 seconds)

      return () => clearTimeout(timer); // Clear the timeout if the component unmounts
    }
  }, [showCartMessage, setShowCartMessage]); // Depend on showCartMessage to trigger the effect

  return (
    <Container>
      <Row className='justify-content-md-center mt-2 '>
        <Col>
          <Alert
            variant='success'
            show={showCartMessage}
            onClose={() => setShowCartMessage(false)}
            dismissible
          >
            <Alert.Heading>The product was added to your cart!</Alert.Heading>{' '}
            <p>
              <Button
                onClick={goBack}
                className='btn btn-outlined m-3'
                variant='info'
              >
                Go Back
              </Button>{' '}
              <Link to='/cart'>
                <Button
                  variant='success'
                  className='btn btn-outlined m-3'
                >
                  Go to cart
                </Button>
              </Link>
            </p>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default AddedToCartMessageComponent;
