import React from "react";
import { FormControl } from "react-bootstrap";
import "../../css/shared-styles.css"; // Import shared styles

const SearchFilter = ({ searchTerm, onSearchTermChange }) => {
  return (
    <div className="filter-group mt-3">
      <label htmlFor="search-term" className="mb-2">
        <strong>Search</strong>
      </label>
      <FormControl
        id="search-term"
        type="text"
        placeholder="Search products..."
        value={searchTerm}
        onChange={(e) => onSearchTermChange(e.target.value)}
      />
    </div>
  );
};

export default SearchFilter;
