import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import CategorySelector from '../../../components/product/CategorySelector';
import SubCategorySelector from '../../../components/product/SubCategorySelector';
import NewCategory from '../../../components/product/NewCategory';
import NewSubCategory from '../../../components/product/NewSubCategory';
import AttributeSelectors from '../../../components/product/AttributeSelectors';
import AddAttributes from '../../../components/product/AddAttributes';
import ProductAttributes from '../../../components/product/ProductAttributes';

import UploadImages from '../../../components/product/UploadImages';

const AdminCreateProductPageComponent = ({
  subs,
  createProductReduxAction,
  insertNewCategoryReduxAction,
  insertNewSubReduxAction,
  reduxDispatch,
  deleteSubReduxAction,
  deleteCategoryReduxAction,
  saveAttributeToCatDoc,
  categories,
}) => {
  const [validated, setValidated] = useState(false);
  const [categoryChosen, setCategoryChosen] = useState('');
  const [selectedSubs, setSelectedSubs] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [newSubCategory, setNewSubCategory] = useState('');
  const [attributeKey, setAttributeKey] = useState('');
  const [newAttributeKey, setNewAttributeKey] = useState('');
  const [newAttributeValue, setNewAttributeValue] = useState([]);
  const [attributeValue, setAttributeValue] = useState([]);
  const [attributesFromDatabase, setAttributesFromDatabase] = useState([]);
  const [attributesTable, setAttributesTable] = useState([]);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {}, [categories]);

  useEffect(() => {
    const categoryAttrs =
      categories && categories.find((c) => c._id === categoryChosen);
    if (categoryAttrs && categoryAttrs.attrs) {
      setAttributesFromDatabase(categoryAttrs.attrs);
    } else {
      setAttributesFromDatabase([]);
    }
  }, [categoryChosen, categories]);

  const handleCategoryChange = (e) => {
    setCategoryChosen(e.target.value.trim());
    setSelectedSubs([]);
  };

  const handleSubCategoryChange = (e) => {
    const subId = e.target.value;
    const isChecked = e.target.checked;
    setSelectedSubs((prevSubs) => {
      const newSubs = new Set(prevSubs);
      if (isChecked) {
        newSubs.add(subId);
      } else {
        newSubs.delete(subId);
      }
      return Array.from(newSubs);
    });
  };

  const handleAddNewCategory = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!newCategory) {
        toast.error('Please enter a category name');
      } else {
        reduxDispatch(
          insertNewCategoryReduxAction(newCategory, (newCategoryId) => {
            setCategoryChosen(newCategoryId);
          })
        );
        setNewCategory('');
      }
    }
  };

  const handleAddNewSubCategory = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!newSubCategory) {
        toast.error('Please enter a sub category name');
      } else {
        reduxDispatch(
          insertNewSubReduxAction(
            { name: newSubCategory, parentId: categoryChosen },
            (newSubId) => {
              setSelectedSubs((prevSubs) => [...prevSubs, newSubId]);
            }
          )
        );
        setNewSubCategory('');
      }
    }
  };

  const handleDeleteSubCategoryHandler = () => {
    const element = document.getElementById('selectedSubCategory');
    reduxDispatch(deleteSubReduxAction(element.value));
  };

  const handleDeleteCategoryHandler = () => {
    const element = document.getElementById('categoryChosen');
    if (element && element.value) {
      reduxDispatch(
        deleteCategoryReduxAction(
          element.value,
          () => {
            toast.success('Category deleted successfully');
          },
          (errorMessage) => {
            toast.error(`Error deleting category: ${errorMessage}`);
          }
        )
      );
    } else {
      toast.error('Invalid category selection');
    }
  };

  const handleDeleteAttribute = (key) => {
    setAttributesTable((prevTable) =>
      prevTable.filter((item) => item.key !== key)
    );
  };

  const handleAttributeKeyChange = (e) => {
    const selectedKey = e.target.value;
    setAttributeKey(selectedKey);

    const relatedAttrs = attributesFromDatabase.find(
      (attr) => attr.key === selectedKey
    );
    if (relatedAttrs) {
      const checkboxValues = relatedAttrs.value.map((value) => ({
        value,
        checked: false,
      }));
      setAttributeValue(checkboxValues);
    } else {
      setAttributeValue([]);
    }
  };

  const handleAttributeValueChange = (e) => {
    const { value, checked } = e.target;
    setAttributeValue((prevValues) =>
      prevValues.map((item) =>
        item.value === value ? { ...item, checked } : item
      )
    );
  };

  const handleNewAttributeKey = (e) => {
    setNewAttributeKey(e.target.value);
  };

  const handleNewAttributeValue = (e) => {
    setNewAttributeValue(e.target.value);
  };

  const addAttributeToTable = () => {
    if (!categoryChosen) {
      toast.error('Please select a category.');
      return;
    }
    if (!attributeKey) {
      toast.error('Please select an attribute key.');
      return;
    }
    if (attributeValue.every((item) => !item.checked)) {
      toast.error('Please select at least one value for the attribute.');
      return;
    }

    // Filter out only checked values and map to their 'value' property
    const selectedValues = attributeValue
      .filter((item) => item.checked)
      .map((item) => item.value);

    // Check if the attribute already exists in the table
    const existingAttribute = attributesTable.find(
      (attr) => attr.key === attributeKey
    );
    if (existingAttribute) {
      // Merge new values with existing ones ensuring uniqueness
      const updatedValues = Array.from(
        new Set([...existingAttribute.value, ...selectedValues])
      );
      setAttributesTable(
        attributesTable.map((attr) =>
          attr.key === attributeKey ? { ...attr, value: updatedValues } : attr
        )
      );
    } else {
      // Add new attribute to the table
      setAttributesTable([
        ...attributesTable,
        { key: attributeKey, value: selectedValues },
      ]);
    }

    toast.success('Attribute added successfully.');
  };

  const handleAddAttrsAndSaveToCategoryDocument = () => {
    // Check if a category is chosen
    if (!categoryChosen) {
      toast.error('Please select a category before adding attributes.');
      return;
    }

    // Check if both key and value for the new attribute are provided
    if (!newAttributeKey || !newAttributeValue) {
      toast.error('Please enter both an attribute key and a value.');
      return;
    }

    // Find if the attribute key already exists in the table
    const existingAttributeIndex = attributesTable.findIndex(
      (attr) => attr.key === newAttributeKey
    );

    let isNewAttribute = existingAttributeIndex === -1;
    let updatedValues = [];

    if (isNewAttribute) {
      // Attribute does not exist, add it to the table with the new value in an array
      attributesTable.push({
        key: newAttributeKey,
        value: [newAttributeValue],
      });
      updatedValues = [newAttributeValue];
    } else {
      // Attribute exists, add new value if it's not already included
      let attribute = attributesTable[existingAttributeIndex];
      let valueSet = new Set([...attribute.value, newAttributeValue]);
      updatedValues = Array.from(valueSet);

      // Update the specific attribute's values
      attributesTable[existingAttributeIndex] = {
        ...attribute,
        value: updatedValues,
      };
    }

    // Update the state to reflect changes
    setAttributesTable([...attributesTable]);
    toast.success('Attribute added successfully.');

    // Dispatch the saveAttributeToCatDoc action
    reduxDispatch(
      saveAttributeToCatDoc(newAttributeKey, updatedValues, categoryChosen)
    );

    // Optionally clear input fields after adding
    setNewAttributeKey('');
    setNewAttributeValue('');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    const formData = new FormData();

    formData.append('name', form.elements['name'].value);
    formData.append('description', form.elements['description'].value);
    formData.append('count', form.elements['count'].value);
    formData.append('price', form.elements['price'].value);
    formData.append('category', form.elements['category'].value);
    formData.append('images', JSON.stringify(images));
    selectedSubs.forEach((subId) => formData.append('subs', subId));

    // Convert attributesTable to a JSON string

    formData.append('attributesTable', JSON.stringify(attributesTable));

    if (event.currentTarget.checkValidity() === true) {
      // Dispatch the Redux action here
      reduxDispatch(
        createProductReduxAction(formData, () => {
          toast.success('Product created successfully');
          navigate('/admin/products');
        })
      );
    }

    setValidated(true);
  };

  return (
    <Container>
      <Row className='justify-content-md-center mt-5'>
        <Col md={1}>
          <Link
            to='/admin/products'
            className='btn btn-info my-3'
          >
            Go Back
          </Link>
        </Col>
        <Col md={6}>
          <h1>Create a new product</h1>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Form.Group
              className='mb-3'
              controlId='formBasicName'
            >
              <Form.Label>Name</Form.Label>
              <Form.Control
                name='name'
                required
                type='text'
              />
            </Form.Group>
            <Form.Group
              className='mb-3'
              controlId='exampleForm.ControlTextarea1'
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                name='description'
                required
                as='textarea'
                type='text'
                rows={3}
              />
            </Form.Group>
            <Form.Group
              className='mb-3'
              controlId='formBasicCount'
            >
              <Form.Label>Count in stock</Form.Label>
              <Form.Control
                name='count'
                required
                type='number'
              />
            </Form.Group>
            <Form.Group
              className='mb-3'
              controlId='formBasicPrice'
            >
              <Form.Label>Price</Form.Label>
              <Form.Control
                name='price'
                required
                type='text'
              />
            </Form.Group>
            <CategorySelector
              categories={categories}
              categoryChosen={categoryChosen}
              handleCategoryChange={handleCategoryChange}
              handleDeleteCategoryHandler={handleDeleteCategoryHandler}
            />
            <SubCategorySelector
              subs={subs}
              categoryChosen={categoryChosen}
              handleDeleteSubCategoryHandler={handleDeleteSubCategoryHandler}
              selectedSubs={selectedSubs}
              handleSubCategoryChange={handleSubCategoryChange}
            />
            <NewCategory
              newCategory={newCategory}
              setNewCategory={setNewCategory}
              handleAddNewCategory={handleAddNewCategory}
            />
            <NewSubCategory
              categoryChosen={categoryChosen}
              newSubCategory={newSubCategory}
              setNewSubCategory={setNewSubCategory}
              handleAddNewSubCategory={handleAddNewSubCategory}
            />
            <AttributeSelectors
              attributeKey={attributeKey}
              attributeValue={attributeValue}
              handleAttributeKeyChange={handleAttributeKeyChange}
              handleAttributeValueChange={handleAttributeValueChange}
              categoryChosen={categoryChosen}
              attributesFromDatabase={attributesFromDatabase}
              addAttributeToTable={addAttributeToTable}
            />
            <ProductAttributes
              attributesTable={attributesTable}
              handleDeleteAttribute={handleDeleteAttribute}
            />

            {categoryChosen && (
              <AddAttributes
                newAttributeKey={newAttributeKey}
                newAttributeValue={newAttributeValue}
                handleNewAttributeKey={handleNewAttributeKey}
                handleNewAttributeValue={handleNewAttributeValue}
                handleAddAttrsAndSaveToCategoryDocument={
                  handleAddAttrsAndSaveToCategoryDocument
                }
              />
            )}
            <UploadImages
              images={images}
              setImages={setImages}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
            {JSON.stringify(images)}
            <Button
              variant='success'
              type='submit'
            >
              Create
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminCreateProductPageComponent;
