import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../redux/actions/product/product";
import { Col, Container, Row, Spinner, Alert, Button } from "react-bootstrap";
import CategoryFilter from "../../components/filterQueryResultOptions/CategoryFilter";
import PriceFilter from "../../components/filterQueryResultOptions/PriceFilter";
import AttributeFilter from "../../components/filterQueryResultOptions/AttributeFilter";
import SearchFilter from "../../components/filterQueryResultOptions/SearchFilter";
import SortFilter from "../../components/filterQueryResultOptions/SortFilter";
import FilterActions from "../../components/filterQueryResultOptions/FilterActions";
import ProductForListComponent from "../../components/ProductForListComponent";
import PaginationComponent from "../../components/filterQueryResultOptions/Pagination";
import useDebounce from "../../utils/useDebounce"; // Assuming you've created the useDebounce hook

const WomenPage = () => {
  const dispatch = useDispatch();
  const { products, loading, error, page, pages, totalProducts } = useSelector(
    (state) => state.getProducts
  );
  const { categories } = useSelector((state) => state.getCategories);
  const { attributes } = useSelector((state) => state.getAttributes);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [attrs, setAttrs] = useState({});
  const [sort, setSort] = useState("popularity");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loaded, setLoaded] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const appliedFilters = useMemo(
    () => ({
      category: selectedCategory ? selectedCategory.value : "",
      subCategory: selectedSubcategory ? selectedSubcategory.value : "",
      priceRange: priceRange.join(","),
      attrs: Object.entries(attrs)
        .map(([key, value]) => `${key}:${value}`)
        .join(","),
      sort,
      searchTerm: debouncedSearchTerm,
      page: currentPage,
    }),
    [
      selectedCategory,
      selectedSubcategory,
      priceRange,
      attrs,
      sort,
      debouncedSearchTerm,
      currentPage,
    ]
  );

  useEffect(() => {
    setLoaded(false); // Reset loaded state before fetching
    dispatch(fetchProducts(appliedFilters)).then(() => setLoaded(true));
  }, [appliedFilters, dispatch]);

  const handleCategoryChange = useCallback((category) => {
    setSelectedCategory(category);
    setSelectedSubcategory(null);
    setCurrentPage(1);
  }, []);

  const handleSubcategoryChange = useCallback((subcategory) => {
    setSelectedSubcategory(subcategory);
    setCurrentPage(1);
  }, []);

  const handlePriceChange = useCallback((range) => {
    setPriceRange(range);
    setCurrentPage(1);
  }, []);

  const handleAttrChange = useCallback((key, value) => {
    setAttrs((prevAttrs) => {
      const newAttrs = { ...prevAttrs };
      if (value) {
        newAttrs[key] = value;
      } else {
        delete newAttrs[key];
      }
      return newAttrs;
    });
    setCurrentPage(1);
  }, []);

  const handleSortChange = useCallback((sortOption) => {
    setSort(sortOption.value);
    setCurrentPage(1);
  }, []);

  const handleSearchTermChange = useCallback((term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  }, []);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleResetFilters = useCallback(() => {
    setSelectedCategory(null);
    setSelectedSubcategory(null);
    setPriceRange([0, 1000]);
    setAttrs({});
    setSort("popularity");
    setSearchTerm("");
    setCurrentPage(1);
  }, []);

  return (
    <Container>
      <Row>
        <Col md={3}>
          <h2>Filter Products</h2>
          <CategoryFilter
            categories={categories}
            selectedCategory={selectedCategory}
            selectedSubcategory={selectedSubcategory}
            onCategoryChange={handleCategoryChange}
            onSubcategoryChange={handleSubcategoryChange}
          />
          <PriceFilter
            priceRange={priceRange}
            onPriceChange={handlePriceChange}
          />
          <AttributeFilter
            attributes={attributes}
            selectedAttrs={attrs}
            onAttrChange={handleAttrChange}
          />
          <SearchFilter
            searchTerm={searchTerm}
            onSearchTermChange={handleSearchTermChange}
          />
          <SortFilter sortBy={sort} onSortChange={handleSortChange} />
          <FilterActions onResetFilters={handleResetFilters} />
        </Col>

        <Col md={9}>
          <Alert variant="info">
            Filters applied:
            {selectedCategory && (
              <span>Category: {selectedCategory.label}</span>
            )}
            {selectedSubcategory && (
              <span>, Subcategory: {selectedSubcategory.label}</span>
            )}
            {Object.keys(attrs).length > 0 && (
              <span>
                , Attributes:{" "}
                {Object.entries(attrs)
                  .map(([key, value]) => `${key}: ${value}`)
                  .join(", ")}
              </span>
            )}
          </Alert>

          {loading ? (
            <Spinner animation="border" />
          ) : error ? (
            <Alert variant="danger">
              {error}
              <Button variant="link" onClick={handleResetFilters}>
                Reset Filters
              </Button>
            </Alert>
          ) : products.length === 0 ? (
            <Alert variant="warning">
              No products found. Please adjust your filters.
              <Button variant="link" onClick={handleResetFilters}>
                Reset All Filters
              </Button>
            </Alert>
          ) : (
            <>
              <Row className={`product-list ${loaded ? "loaded" : ""}`}>
                {products.map((product) => (
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    key={product._id}
                    className="d-flex justify-content-center"
                  >
                    <ProductForListComponent product={product} />
                  </Col>
                ))}
              </Row>
              {pages > 1 && (
                <PaginationComponent
                  page={currentPage}
                  pages={pages}
                  setPage={handlePageChange}
                />
              )}
              <p className="mt-3">
                Showing page {page} of {pages} ({totalProducts} products found)
              </p>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default WomenPage;
