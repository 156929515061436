import React from 'react';
import { Form } from 'react-bootstrap';

const NewSubCategory = ({
  categoryChosen,
  newSubCategory,
  setNewSubCategory,
  handleAddNewSubCategory,
}) => {
  return (
    <div>
      {categoryChosen && (
        <Form.Group
          className='mb-3'
          controlId='formBasicNewSubCategory'
        >
          <Form.Label className='text-warning'>
            Or create a new sub category (e.g. Silk/Trousers/Dresses)
          </Form.Label>
          <Form.Control
            name='newSubCategory'
            type='text'
            value={newSubCategory}
            onChange={(e) => setNewSubCategory(e.target.value)}
            onKeyDown={handleAddNewSubCategory}
            placeholder='Enter a sub category name'
          />
        </Form.Group>
      )}
    </div>
  );
};

export default NewSubCategory;
