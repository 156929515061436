import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button, Container, Form, Row, Col, NavItem } from "react-bootstrap";
const RequestResetPasswordScreen = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post("/api/users/request-reset-password", {
        email,
      });
      if (response.data.message) {
        toast.success(response.data.message);
      } else {
        toast.success("Password reset email sent");
        setEmail("");
        navigate("/sign-in");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while requesting password reset");
    }
  };

  return (
    <>
      <Container className="auth-container" fluid>
        <Row className="display-flex justify-content-md-center mb-3 mt-3 p-4 sign-in-form">
          <Col md={6}>
            <Form onSubmit={handleSubmit} className="auth-form">
              <h4>Request Password Reset</h4>
              <Form.Group className="mb-3">
                <Form.Label className="form-label" htmlFor="email">
                  Email
                </Form.Label>
                <Form.Control
                  type="email"
                  id="email"
                  name="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="form-label" />
                <Button type="submit">Request Password Reset</Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RequestResetPasswordScreen;
