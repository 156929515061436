import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";
import { toast } from "react-toastify";
import axios from "axios";

const AdminUsersPageComponent = ({ fetchUsers, deleteUser }) => {
  const [users, setUsers] = useState([]);
  const [userDeleted, setUserDeleted] = useState(false);

  const deleteHandler = async (userId) => {
    if (window.confirm("Are you sure")) {
      await deleteUser(userId)
        .then((res) => {
          if (res.message === "user deleted successfully") {
            setUserDeleted(!userDeleted);
          }
        })
        .catch((error) => {
          setUserDeleted(false);

          const errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : error.message || "Unknown error";

          toast.error(errorMessage);
        });
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    try {
      fetchUsers(controller)
        .then((res) => {
          setUsers(res);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log("Failed to fetch the user");
          } else {
            toast.error("Failed to fetch user");
          }
        });
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message || "Unknown error";

      toast.error(errorMessage);
    }

    return () => {
      controller.abort(); // This will abort the request if the component unmounts or the dependencies change
    };
  }, [fetchUsers, userDeleted]); // Dependencies array should include all variables from the component scope that affect this effect

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col md={2}>
          <AdminLinksComponent />
        </Col>
        <Col md={10}>
          <h1>User List</h1>

          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>isAdmin</th>
                <th>Edit/Delete</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.lastName}</td>
                  <td>{user.email}</td>
                  <td>
                    {user.isAdmin ? (
                      <i className="bi bi-check-lg text-success"></i>
                    ) : (
                      <i className="bi bi-x-lg text-danger "></i>
                    )}
                  </td>
                  <td>
                    <Link to={`/admin/edit-user/${user._id}`}>
                      <Button className="btn-sm">
                        {" "}
                        <i className="bi bi-pencil-square"></i>
                      </Button>
                    </Link>

                    {" / "}
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={() => deleteHandler(user._id)}
                    >
                      {" "}
                      <i className="bi bi-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminUsersPageComponent;
