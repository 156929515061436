import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
} from "react-bootstrap";
import CartItemComponent from "../../../components/cart/CartItemComponent";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";

const AdminOrderDetailsPageComponent = ({
  fetchOrder,
  markAsDelivered,
  logoutUserReduxAction,
}) => {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const [cartItems, setCartItems] = useState([]);
  const [cartSubtotal, setCartSubtotal] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [orderButtonMessage, setOrderButtonMessage] =
    useState("Mark as delivered");

  const [isPaid, setIsPaid] = useState(false);
  const [isDelivered, setIsDelivered] = useState(false);

  useEffect(() => {
    fetchOrder(id)
      .then((order) => {
        setUser(order.user);
        setPaymentMethod(order.paymentMethod);
        order.isPaid ? setIsPaid(order.paidAt) : setIsPaid(false);
        order.isDelivered
          ? setIsDelivered(order.deliveredAt)
          : setIsDelivered(false);
        setCartSubtotal(order.orderTotal.cartSubtotal);
        if (order.isDelivered) {
          setOrderButtonMessage("Order is finished");
          setButtonDisabled(true);
        }
        setCartItems(order.cartItems);
      })
      .catch((error) => {
        dispatch(logoutUserReduxAction());

        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Unknown error occurred!";
        toast.error(errorMessage);
      });
  }, [dispatch, logoutUserReduxAction, fetchOrder, isDelivered, id]);

  const handleDelivery = (id) => {
    markAsDelivered(id)
      .then((res) => {
        if (res) {
          setIsDelivered(true);
          setButtonDisabled(true); // Corrected from buttonDisabled(true)
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Unknown error occurred!";
        toast.error(errorMessage);
      });
  };
  return (
    <Container>
      <Row>
        <h1 className="text-centre text-primary">Order Details</h1>
        <Col md={8}>
          <Row>
            <Col md={6}>
              <h3>Shipping</h3>
              <b>Name</b>: {user.name} {user.lastName}
              <br />
              <b>Address</b>: {user.address} {user.city} {user.state}{" "}
              {user.postalCode}
              <br />
              <b>Phone Number</b>:{user.phoneNumber}
            </Col>
            <Col md={6}>
              <h3>Payment Method</h3>
              <Form.Select value={paymentMethod} disabled={true}>
                <option value="pp">Paypal</option>
                <option value="stripe">Stripe</option>
              </Form.Select>
            </Col>
            <Row>
              <Col>
                <Alert
                  className="mt-3"
                  variant={isDelivered ? "success" : "danger"}
                >
                  {isDelivered ? (
                    <>deliveredAt at {isDelivered}</>
                  ) : (
                    "Not delivered"
                  )}
                </Alert>
              </Col>
              <Col>
                <Alert className="mt-3" variant={isPaid ? "success" : "danger"}>
                  {isPaid ? <>Paid at {isPaid}</> : "Not paid yet"}
                </Alert>
              </Col>
            </Row>
          </Row>
          <br />
          <h2>Order items</h2>
          <ListGroup variant="flush">
            {cartItems &&
              cartItems.map((item, index) => (
                <CartItemComponent
                  item={item}
                  key={index}
                  orderCreated={true}
                />
              ))}
          </ListGroup>
        </Col>

        <Col md={4}>
          <ListGroup>
            <ListGroup.Item>
              <h3>Order Summary</h3>
            </ListGroup.Item>
            <ListGroup.Item>
              Items price (after tax):{" "}
              <span className="fw-bold">£{cartSubtotal}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              Shipping: <span className="fw-bold">Included</span>
            </ListGroup.Item>
            <ListGroup.Item>
              Tax: <span className="fw-bold">Included</span>
            </ListGroup.Item>
            <ListGroup.Item className="text-danger">
              Total price: <span className="fw-bold">£{cartSubtotal}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="d-grid gap-2">
                <Button
                  size="lg"
                  variant="danger"
                  type="button"
                  disabled={buttonDisabled}
                  onClick={() => handleDelivery(id)}
                >
                  {orderButtonMessage}
                </Button>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      <Row></Row>
    </Container>
  );
};

export default AdminOrderDetailsPageComponent;
