import React from "react";
import { Col, Container, Row, Alert, ListGroup, Button } from "react-bootstrap";
import CartItemComponent from "../../components/cart/CartItemComponent";
import { Link } from "react-router-dom";
import "../../css/cart-page.css";
const CartPageComponent = ({
  cartItems,
  cartSubtotal,
  reduxDispatch,
  addToCartReduxAction,
  removeProductFromCartAction,
}) => {
  const removeFromCartHandler = (productID) => {
    if (window.confirm("Are you sure ??"))
      reduxDispatch(removeProductFromCartAction(productID));
  };
  const changeCount = (productID, count) => {
    if (count > 0) {
      reduxDispatch(addToCartReduxAction(productID, count));
    } else {
      // Optionally handle cases where count might be zero or invalid
      // This might include removing an item or ignoring the dispatch
    }
  };

  return (
    <Container fluid>
      <Row className="cart-container">
        <Row>
          <Col>
            <h2>Shopping Cart</h2>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12} md={8}>
            {cartItems &&
              cartItems.map((item, idx) => (
                <CartItemComponent
                  item={item}
                  key={idx}
                  changeCount={changeCount}
                  removeFromCartHandler={removeFromCartHandler}
                />
              ))}

            <div>
              <Alert
                className="mt-3"
                variant="info"
                show={cartItems.length < 1}
              >
                Your cart is empty. <Link to="/shop">Go shopping</Link>
              </Alert>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                {cartItems &&
                  cartItems.map((i) => (
                    <div className="price-display">
                      <span>
                        Subtotal(
                        {i.quantity} {i.quantity === 1 ? "item" : "items"}
                        ):
                      </span>
                      <span className="pound-sign">£</span>
                      <span className="price-value">{cartSubtotal}</span>
                    </div>
                  ))}
              </ListGroup.Item>
              <ListGroup.Item>
                <Link to="/user/cart-details">
                  <div className="d-grid gap-2">
                    <Button
                      disabled={cartSubtotal === 0}
                      className="cart-page-button"
                      type="button"
                    >
                      Proceed to checkout
                    </Button>
                  </div>
                </Link>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

export default CartPageComponent;
