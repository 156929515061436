import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const UserProtectedRoutes = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/api/get-token", { withCredentials: true })
      .then((response) => {
        if (!response.data.isAdmin) {
          // Ensure non-admins only
          setUser(response.data);
        } else {
          setUser(null); // Admins should not use user routes
        }
      })
      .catch((error) => {
        setUser(null);

        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Unknown error occurred!";
        toast.error(errorMessage);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user || user.isAdmin) {
    return <Navigate to="/sign-in" replace />;
  }

  return <Outlet />;
};

export default UserProtectedRoutes;
