import React from 'react';
import CartPageComponent from './components/CartPageComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCartReduxAction,
  removeProductFromCartAction,
} from '../redux/actions/cart/cartActions';
const CartPage = () => {
  const { cartItems } = useSelector((state) => state.cart);
  const cartSubtotal = useSelector((state) => state.cart.cartSubtotal);
  const dispatch = useDispatch();
  const reduxDispatch = dispatch;
  return (
    <CartPageComponent
      cartItems={cartItems}
      reduxDispatch={reduxDispatch}
      cartSubtotal={cartSubtotal}
      addToCartReduxAction={addToCartReduxAction}
      removeProductFromCartAction={removeProductFromCartAction}
    />
  );
};

export default CartPage;
