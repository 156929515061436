import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "../../css/shared-styles.css"; // Import shared styles

const PriceFilter = ({ priceRange, onPriceChange }) => {
  return (
    <div className="filter-group mt-3">
      <label htmlFor="price-range" className="mb-2">
        <span>
          <h4>Price</h4>
        </span>
      </label>
      <Slider
        id="price-range"
        range
        min={0}
        max={1000}
        value={priceRange}
        onChange={onPriceChange}
      />
      <div className="price-range-label">
        <span>
          <span className="range-text"> Price Range:</span>

          <span className="price-value">
            <span className="pound-sign">£</span>
            {priceRange[0]} - <span className="pound-sign">£</span>
            {priceRange[1]}
          </span>
        </span>
      </div>
    </div>
  );
};

export default PriceFilter;
