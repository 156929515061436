import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const AdminSubsPageComponent = ({ fetchSubs, deleteSub }) => {
  const [subs, setSubs] = useState([]);

  useEffect(() => {
    fetchSubs()
      .then((res) => {
        setSubs(res);
      })
      .catch((error) => {
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message || "Unknown error";

        toast.error(errorMessage);
      });
  }, [fetchSubs]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?"))
      deleteSub(id)
        .then((res) => {
          toast.success(res.message);
          setSubs(subs.filter((sub) => sub._id !== id));
        })
        .catch((error) => {
          const errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : error.message || "Unknown error";

          toast.error(errorMessage);
        });
  };

  return (
    <Container>
      <Row className="justify-content-md-center mt-4">
        <Col md={3}>
          <AdminLinksComponent />
        </Col>
        <Col md={9}>
          <Row>
            <Col>
              <h1>Sub List</h1>
            </Col>
            <Col>
              <Link to="/admin/sub-create">
                <Button>Create new sub</Button>
              </Link>
            </Col>
          </Row>
          <Table bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Parent ID</th>
                <th>Delete</th>
                <th>Update</th>
              </tr>
            </thead>
            <tbody>
              {subs &&
                subs.map((sub, idx) => (
                  <tr key={sub._id}>
                    <td>{idx + 1}</td>
                    <td>{sub.name}</td>
                    <td>{sub.parentId}</td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => deleteHandler(sub._id)}
                      >
                        <i className="bi bi-trash3"></i>
                      </Button>
                    </td>
                    <td>
                      <Link to={`/admin/sub-edit/${sub._id}`}>
                        <Button variant="primary">
                          <i className="bi bi-pencil-square"></i>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminSubsPageComponent;
