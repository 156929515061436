import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";
import { toast } from "react-toastify";
import axios from "axios";

const AdminProductsPageComponent = ({ listProducts, deleteProduct }) => {
  const [products, setProducts] = useState([]);
  const [productDeleted, setProductDeleted] = useState(false);

  const deleteHandler = async (id) => {
    try {
      if (window.confirm("Are you sure")) {
        await deleteProduct(id)
          .then((res) => {
            if (res.message === "product deleted successfully") {
              setProductDeleted(!productDeleted);
              toast.success(res.message);
            }
          })
          .catch((error) => {
            setProductDeleted(false);

            const errorMessage =
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : error.message || "Unknown error";

            toast.error(errorMessage);
          });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message || "Unknown error";

      toast.error(errorMessage);
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    try {
      listProducts(controller)
        .then((res) => {
          setProducts(res);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.error(error.message);
          } else {
            toast.error(error.message);
          }
        });
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message || "Unknown error";

      toast.error(errorMessage);
    }

    return () => controller.abort();
  }, [listProducts, productDeleted]);
  return (
    <Container>
      <Row className="mt-5 ">
        <Col md={2}>
          <AdminLinksComponent />
        </Col>
        <Col md={10}>
          <Row>
            <Col md={6}>
              <h1 className="text-primary">Product List</h1>
            </Col>
            <Col md={6}>
              <Link to="/admin/create-new-product">
                <Button variant="info" size="lg">
                  Create new Product
                </Button>
              </Link>
            </Col>
          </Row>

          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>PRODUCT NAME</th>
                <th>PRICE</th>
                <th>CATEGORY</th>
                <th>Subs</th>
                <th>EDIT/DELETE</th>
              </tr>
            </thead>
            <tbody>
              {products.map((p, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{p.name}</td>
                  <td>{p.price}</td>
                  <td>{p.category}</td>
                  <td>{p.subs}</td>
                  <td>
                    <Link to={`/admin/edit-product/${p._id}`}>
                      <Button className="btn-sm">
                        <i className="bi bi-pencil-square"></i>
                      </Button>
                    </Link>

                    <Button
                      onClick={() => deleteHandler(p._id)}
                      variant="danger"
                      className="btn-sm"
                    >
                      <i className="bi bi-x-square"></i>
                    </Button>
                  </td>
                  <td>Stripe</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminProductsPageComponent;
