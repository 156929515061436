import React from "react";
import { Col, Container, Row } from "react-bootstrap";
const FooterComponent = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <Container fluid>
      <Row>
        <Col className="d-flex justify-content-center align-items-centre">
          <h6> Copyright &copy; {currentYear} ACADEMY LONDON READY-MADE</h6>
        </Col>
      </Row>
    </Container>
  );
};

export default FooterComponent;
