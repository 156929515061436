import {
  legacy_createStore as createStore,
  applyMiddleware,
  combineReducers,
  compose,
} from "redux";

import { thunk } from "redux-thunk";
import { authUserReducer } from "./reducers/auth/userReducers";
import { cartReducer } from "./reducers/cart/cartReducer";
import { getCategoriesReducer } from "./reducers/category/categoryReducers";
import { getSubsReducer } from "./reducers/subs/subReducer";

import { searchReducer } from "./reducers/searchReducer";
import { adminChatReducer } from "./reducers/chat/chatReducer";
import { passwordResetReducer } from "./reducers/password/passwordReducer";
import {
  attributesReducer,
  getProductsReducer,
  menProductsReducer,
  productReducer,
} from "./reducers/products/product";
const reducers = combineReducers({
  authUser: authUserReducer,
  cart: cartReducer,
  getCategories: getCategoriesReducer,
  getSubs: getSubsReducer,

  search: searchReducer,
  chat: adminChatReducer,
  passwordReset: passwordResetReducer,
  getCategories: getCategoriesReducer,
  getProducts: productReducer,
  getAttributes: attributesReducer,
  menProducts: menProductsReducer,
});

let userInfoInLocalStorage = null;
try {
  const localStorageUserInfo = localStorage.getItem("userInfo");
  const sessionStorageUserInfo = sessionStorage.getItem("userInfo");

  if (localStorageUserInfo) {
    userInfoInLocalStorage = JSON.parse(localStorageUserInfo);
  } else if (sessionStorageUserInfo) {
    userInfoInLocalStorage = JSON.parse(sessionStorageUserInfo);
  }
} catch (error) {
  console.error("Error parsing user info in storage");
  userInfoInLocalStorage = null;
}

const cartItemsInLocalStorage = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : [];

const isCartArray = Array.isArray(cartItemsInLocalStorage);

const INITIAL_STATE = {
  authUser: { userInfo: userInfoInLocalStorage },
  cart: {
    cartItems: isCartArray ? cartItemsInLocalStorage : [],
    itemsCount: isCartArray
      ? cartItemsInLocalStorage.reduce(
          (quantity, item) => Number(item.quantity) + quantity,
          0
        )
      : 0,
    cartSubtotal: isCartArray
      ? cartItemsInLocalStorage.reduce(
          (price, item) => price + Number(item.price) * Number(item.quantity),
          0
        )
      : 0,
  },
};

const middleware = [thunk];

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middleware)
  // other store enhancers if any
);

const store = createStore(reducers, INITIAL_STATE, enhancer);

export default store;
