import React from "react";
import AdminCreateSubsPageComponent from "./components/AdminCreateSubsPageComponent";

import { useSelector } from "react-redux";
import { createSubCategoryReduxAction } from "../../redux/actions/subs/subActions";

const AdminCreateSubsPage = () => {
  const { categories } = useSelector((state) => state.getCategories);
  return (
    <AdminCreateSubsPageComponent
      categories={categories}
      createSubCategoryReduxAction={createSubCategoryReduxAction}
    />
  );
};

export default AdminCreateSubsPage;
