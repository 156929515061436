import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Offcanvas,
  Button,
  Container,
  Row,
  Col,
  Alert,
  Spinner,
} from "react-bootstrap";
import { fetchProducts } from "../../redux/actions/product/product";
import CategoryFilter from "../../components/filterQueryResultOptions/CategoryFilter";
import PriceFilter from "../../components/filterQueryResultOptions/PriceFilter";
import AttributeFilter from "../../components/filterQueryResultOptions/AttributeFilter";
import SortFilter from "../../components/filterQueryResultOptions/SortFilter";
import FilterActions from "../../components/filterQueryResultOptions/FilterActions";
import ProductForListComponent from "../../components/ProductForListComponent";
import PaginationComponent from "../../components/filterQueryResultOptions/Pagination";
import SearchFilter from "../../components/filterQueryResultOptions/SearchFilter";
import "../../css/shop-page.css";

const ShopPageComponent = () => {
  const dispatch = useDispatch();
  const { products, loading, error, page, pages, totalProducts } = useSelector(
    (state) => state.getProducts
  );
  const { categories } = useSelector((state) => state.getCategories);
  const { attributes } = useSelector((state) => state.getAttributes);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [attrs, setAttrs] = useState({});
  const [sort, setSort] = useState("popularity");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showOffCanvas, setShowOffCanvas] = useState(false);

  const appliedFilters = useMemo(
    () => ({
      category: selectedCategory ? selectedCategory.value : "",
      subCategory: selectedSubcategory ? selectedSubcategory.value : "",
      priceRange: priceRange.join(","),
      attrs: Object.entries(attrs)
        .map(([key, value]) => `${key}:${value}`)
        .join(","),
      sort,
      searchTerm: searchTerm,
      page: currentPage,
    }),
    [
      selectedCategory,
      selectedSubcategory,
      priceRange,
      attrs,
      sort,
      searchTerm,
      currentPage,
    ]
  );

  useEffect(() => {
    dispatch(fetchProducts(appliedFilters));
  }, [appliedFilters, dispatch]);

  // Handlers to update state
  const handleCategoryChange = useCallback((category) => {
    setSelectedCategory(category);
    setSelectedSubcategory(null);
    setCurrentPage(1);
  }, []);

  const handleSubcategoryChange = useCallback((subcategory) => {
    setSelectedSubcategory(subcategory);
    setCurrentPage(1);
  }, []);

  const handlePriceChange = useCallback((range) => {
    setPriceRange(range);
    setCurrentPage(1);
  }, []);

  const handleAttrChange = useCallback((key, value) => {
    setAttrs((prevAttrs) => {
      const newAttrs = { ...prevAttrs };
      if (value) {
        newAttrs[key] = value;
      } else {
        delete newAttrs[key];
      }
      return newAttrs;
    });
    setCurrentPage(1);
  }, []);

  const handleSortChange = useCallback((sortOption) => {
    setSort(sortOption.value);
    setCurrentPage(1);
  }, []);

  const handleSearchTermChange = useCallback((term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  }, []);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleResetFilters = useCallback(() => {
    setSelectedCategory(null);
    setSelectedSubcategory(null);
    setPriceRange([0, 1000]);
    setAttrs({});
    setSort("popularity");
    setSearchTerm("");
    setCurrentPage(1);
  }, []);

  const handleToggleOffCanvas = () => {
    setShowOffCanvas(!showOffCanvas);
  };

  return (
    <Container fluid>
      <Row>
        <Col className="filter-products-text">
          <h2 className="text-centre">Luxurious ready-made</h2>

          <p className="text-centre">
            Crafted in London, Worn Worldwide: Elevate Your Style with
            Ready-Made Masterpieces Designed by Expert Hands. Experience
            Unmatched Craftsmanship, Now Yours to Wear.
          </p>
        </Col>
      </Row>
      <Row>
        {/* Sidebar only visible on screens with width 1025px and above */}
        <Col className="filter-sidebar d-none d-lg-block" lg={3}>
          <div className="filter-section">
            <CategoryFilter
              categories={categories}
              selectedCategory={selectedCategory}
              selectedSubcategory={selectedSubcategory}
              onCategoryChange={handleCategoryChange}
              onSubcategoryChange={handleSubcategoryChange}
            />
          </div>
          <div className="filter-section">
            <PriceFilter
              priceRange={priceRange}
              onPriceChange={handlePriceChange}
            />
          </div>
          <div className="filter-section">
            <AttributeFilter
              attributes={attributes}
              selectedAttrs={attrs}
              onAttrChange={handleAttrChange}
            />
          </div>
          <div className="filter-section">
            <FilterActions onResetFilters={handleResetFilters} />
          </div>
        </Col>

        <Col lg={9}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <SortFilter sortBy={sort} onSortChange={handleSortChange} />
            {/* Hide search filter on screens with width less than 1025px */}
            <div className="d-none d-lg-block search-filter-wrapper">
              <SearchFilter
                searchTerm={searchTerm}
                onSearchTermChange={handleSearchTermChange}
              />
            </div>
            {/* Show filter button on screens with width less than 1025px */}
            <Button
              variant="primary"
              onClick={handleToggleOffCanvas}
              className="d-lg-none filter-button-wrapper"
            >
              Filter Products
            </Button>
          </div>

          <Alert variant="info">
            Filters applied:
            {selectedCategory && (
              <span>Category: {selectedCategory.label}</span>
            )}
            {selectedSubcategory && (
              <span>, Subcategory: {selectedSubcategory.label}</span>
            )}
            {Object.keys(attrs).length > 0 && (
              <span>
                , Attributes:{" "}
                {Object.entries(attrs)
                  .map(([key, value]) => `${key}: ${value}`)
                  .join(", ")}
              </span>
            )}
          </Alert>

          {loading ? (
            <Spinner animation="border" />
          ) : error ? (
            <Alert variant="danger">
              {error}
              <Button variant="link" onClick={handleResetFilters}>
                Reset Filters
              </Button>
            </Alert>
          ) : products.length === 0 ? (
            <Alert variant="warning">
              No products found. Please adjust your filters.
              <Button variant="link" onClick={handleResetFilters}>
                Reset All Filters
              </Button>
            </Alert>
          ) : (
            <>
              <Row className="product-list">
                {products.map((product) => (
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    key={product._id}
                    className="d-flex justify-content-center"
                  >
                    <ProductForListComponent product={product} />
                  </Col>
                ))}
              </Row>
              {pages > 1 && (
                <PaginationComponent
                  page={currentPage}
                  pages={pages}
                  setPage={handlePageChange}
                />
              )}
              <p className="mt-3">
                Showing page {page} of {pages} ({totalProducts} products found)
              </p>
            </>
          )}
        </Col>
      </Row>

      {/* Offcanvas for screens with width 1024px and below */}
      <Offcanvas
        show={showOffCanvas}
        onHide={() => setShowOffCanvas(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter Products</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="filter-section">
            <CategoryFilter
              categories={categories}
              selectedCategory={selectedCategory}
              selectedSubcategory={selectedSubcategory}
              onCategoryChange={handleCategoryChange}
              onSubcategoryChange={handleSubcategoryChange}
            />
          </div>
          <div className="filter-section">
            <PriceFilter
              priceRange={priceRange}
              onPriceChange={handlePriceChange}
            />
          </div>
          <div className="filter-section">
            <AttributeFilter
              attributes={attributes}
              selectedAttrs={attrs}
              onAttrChange={handleAttrChange}
            />
          </div>
          <div className="filter-section">
            <FilterActions onResetFilters={handleResetFilters} />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
};

export default ShopPageComponent;
