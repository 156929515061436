import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const RegisterPageComponent = ({ registerUserReduxAction }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const validateForm = () => {
    let errors = {};
    if (formData.password !== formData.confirmPassword) {
      errors.password = "Passwords do not match";
    }
    return errors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let errors = validateForm();

    if (Object.keys(errors).length === 0 && form.checkValidity() === true) {
      const userData = {
        name: formData.name,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
      };
      dispatch(
        registerUserReduxAction(userData, () => {
          navigate("/shop");
        })
      );
    } else {
      e.stopPropagation();
      setFormErrors(errors);
      toast.error(errors.password || "Please fill in the form correctly.");
    }

    setValidated(true);
  };

  return (
    <Container className="auth-container" fluid>
      <Row className="sign-in-form">
        <Col md={6}>
          <h2>Register</h2>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="auth-form"
          >
            <Form.Group
              className="mb-3"
              controlId="validationCustomRegisterName"
            >
              <Form.Label className="form-label">Name</Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="First name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="form-control"
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="validationCustomRegisterLastName"
            >
              <Form.Label className="form-label">Last name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last name"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                required
                className="form-control"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustomEmail">
              <Form.Label className="form-label">Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                className="form-control"
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="validationCustomRegisterPassword"
            >
              <Form.Label className="form-label">Password</Form.Label>
              <div className="password-input-container">
                <Form.Control
                  type={passwordShown ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                  className="form-control"
                  isInvalid={!!formErrors.password}
                />
                <span
                  className="password-toggle-icon "
                  onClick={togglePasswordVisibility}
                  aria-label={passwordShown ? "Hide password" : "Show password"}
                >
                  {passwordShown ? <BsEyeSlash /> : <BsEye />}
                </span>
                <Form.Control.Feedback type="invalid">
                  {formErrors.password || "Please provide a valid password."}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="validationCustomRegisterConfirmPassword"
            >
              <Form.Label className="form-label">Confirm password</Form.Label>
              <div className="password-input-container">
                {" "}
                <Form.Control
                  type={passwordShown ? "text" : "password"}
                  placeholder="Confirm password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  required
                  className="form-control"
                  isInvalid={!!formErrors.password}
                />
                <span
                  onClick={togglePasswordVisibility}
                  aria-label={passwordShown ? "Hide password" : "Show password"}
                  className="password-toggle-icon"
                >
                  {passwordShown ? <BsEyeSlash /> : <BsEye />}
                </span>
                <br />
                <Form.Control.Feedback type="invalid">
                  {formErrors.password || "Passwords must match."}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Button type="submit" className="btn">
                Register
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterPageComponent;
