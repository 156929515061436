import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import UploadImages from "../../../components/product/UploadImages";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AdminCreateCategoryPageComponent = ({
  createCategoryReduxAction,
  reduxDispatch,
}) => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    const formData = new FormData();

    // Convert images array to JSON string
    const imagesJSON = JSON.stringify(
      images.map((img) => ({ uri: img.uri, public_id: img.public_id }))
    );

    formData.append("images", imagesJSON);
    formData.append("name", form.elements["name"].value);
    formData.append("description", form.elements["description"].value);

    if (form.checkValidity()) {
      try {
        reduxDispatch(
          createCategoryReduxAction(formData, (categoryId) => {
            if (categoryId) {
              toast.success("Category created successfully");
              navigate("/admin/categories");
            } else {
              toast.error("Failed to create category");
            }
          })
        );
      } catch (error) {
        console.error("Error response:", error.response);
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message || "Unknown error";
        toast.error(errorMessage);
      }
    } else {
      toast.error("Please fill all the required fields correctly");
    }

    setValidated(true);
  };

  return (
    <Container>
      <Row className="justify-content-center w-100">
        <Col md={6}>
          <h1>Create Category</h1>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group
              controlId="formBasicCategoryCreateName"
              className="mt-3"
            >
              <Form.Label>Name</Form.Label>
              <Form.Control name="name" type="text" required />
            </Form.Group>
            <Form.Group
              className="mt-3"
              controlId="formBasicCategoryCreateDescription"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control name="description" type="text" required />
            </Form.Group>
            <UploadImages
              images={images}
              setImages={setImages}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
            <Form.Group>
              <Form.Label />
              <Button type="submit">Create</Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminCreateCategoryPageComponent;
