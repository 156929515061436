// redux/reducers/productReducer.js
import * as actionType from "../../constants/products/product";
import {
  FETCH_MEN_PRODUCTS_REQUEST,
  FETCH_MEN_PRODUCTS_SUCCESS,
  FETCH_MEN_PRODUCTS_FAILURE,
  SET_MEN_FILTERS,
} from "../../constants/products/product";
export const attributesReducer = (
  state = { attributes: [], error: null, loading: false },
  action
) => {
  switch (action.type) {
    case "FETCH_ATTRIBUTES_REQUEST":
      return { ...state, loading: true };

    case "FETCH_ATTRIBUTES_SUCCESS":
      return {
        ...state,
        attributes: action.payload,
        loading: false,
        error: null,
      };
    case "FETCH_ATTRIBUTES_FAILURE":
      return { ...state, loading: false, payload: action.payload };
    default:
      return state;
  }
};

const initialState = {
  filters: {
    category: "",
    subs: "",
    priceRange: "0,1000",
    attrs: "",
    sort: "popularity",
    searchTerm: "",
    page: 1,
  },
  products: [],
  loading: false,
  error: null,
  page: 1,
  pages: 1,
  totalProducts: 0,
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILTERS":
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    case "RESET_FILTERS":
      return {
        ...state,
        filters: { ...initialState.filters },
        products: [], // Clear products when filters are reset
        page: 1,
        pages: 1,
        totalProducts: 0,
      };
    case "FETCH_PRODUCTS_REQUEST":
      return {
        ...state,
        loading: true,
        error: null, // Clear any previous errors
      };
    case "FETCH_PRODUCTS_SUCCESS":
      return {
        ...state,
        loading: false,
        products: action.payload.products,
        page: action.payload.page,
        pages: action.payload.pages,
        totalProducts: action.payload.totalProducts,
      };
    case "FETCH_PRODUCTS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// src/redux/reducers/menProductsReducer.js

const initialMenState = {
  loading: false,
  products: [],
  attrs: [],
  page: 1,
  pages: 1,
  subCategories: [],
  minPrice: 0,
  maxPrice: 1000,
  error: null,
  filters: {
    minPrice: 0,
    maxPrice: 1000,
    page: 1,
    limit: 12,
    attrs: [],
    subCategories: [],
    sortBy: "relevance",
    searchTerm: "", // Initialize searchTerm as empty
  },
};

export const menProductsReducer = (state = initialMenState, action) => {
  switch (action.type) {
    case FETCH_MEN_PRODUCTS_REQUEST:
      return { ...state, loading: true, products: [] };
    case FETCH_MEN_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload.products,
        page: action.payload.page,
        pages: action.payload.pages,
        minPrice: action.payload.minPrice,
        maxPrice: action.payload.maxPrice,
        filters: { ...state.filters, ...action.payload.filters },
        attrs: action.payload.attrs,
        subCategories: action.payload.subCategories,
      };
    case FETCH_MEN_PRODUCTS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case SET_MEN_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    default:
      return state;
  }
};
