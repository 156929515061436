import { toast } from "react-toastify";
import * as actionTypes from "../../constants/category/categoryConstant";
import axios from "axios";

export const getCategoriesReduxAction = () => async (dispatch) => {
  const { data } = await axios.get("/api/categories");
  dispatch({ type: actionTypes.GET_CATEGORIES, payload: data });
};

export const insertNewCategoryReduxAction =
  (name, callback) => async (dispatch, getState) => {
    const existingCategories = getState().getCategories.categories;

    const { data } = await axios.post("/api/categories/insert-new", { name });

    if (data.categoryInserted) {
      dispatch({
        type: actionTypes.INSERT_NEW_CATEGORY,
        payload: [...existingCategories, data.categoryInserted],
      });
      // Execute callback with the new category's ID
      callback(data.categoryInserted._id);
    }
  };

export const deleteCategoryReduxAction =
  (id, callback, errorCallback) => async (dispatch) => {
    try {
      const { data } = await axios.delete(`/api/categories/delete/${id}`);
      if (data.message === "Category deleted successfully") {
        dispatch({
          type: actionTypes.DELETE_NEW_CATEGORY,
          payload: id, // Dispatch only the ID of the deleted category
        });
        if (callback) callback(id); // Pass the deleted ID as confirmation
      } else {
        if (errorCallback) errorCallback(data.message);
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message || "Unknown error";

      toast.error(errorMessage);

      if (errorCallback) errorCallback(errorMessage);
      // Optionally, dispatch an error state update here
    }
  };
// Redux action to save attribute to category

export const saveAttributeToCatDoc =
  (key, value, categoryChosen, callback) => async (dispatch, getState) => {
    try {
      const { data } = await axios.post("/api/categories/save-attrs", {
        key,
        value: Array.isArray(value) ? value : [value],
        categoryChosen,
      });
      if (data.categoryUpdated) {
        dispatch({
          type: actionTypes.SAVE_ATTR,
          payload: {
            categoryId: categoryChosen,
            attrs: data.categoryUpdated.attrs,
          },
        });
        // Ensure callback is a function before calling
        if (typeof callback === "function") {
          callback(null, data.categoryUpdated);
        }
      } else {
        // No update happened, ensure callback is a function before calling
        if (typeof callback === "function") {
          callback(new Error("No category was updated."));
        }
      }
    } catch (error) {
      // Ensure callback is a function before calling
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.response.data;
      toast.error("Action failed: " + errorMessage);
      if (typeof callback === "function") {
        callback(error);
      }
    }
  };

export const editCategoryReduxAction =
  (id, formData, callBack) => async (dispatch) => {
    try {
      const { data } = await axios.put(
        `/api/categories/update/${id}`,
        formData
      );
      dispatch({
        type: actionTypes.EDIT_CATEGORY,
        payload: data,
      });
      if (callBack) callBack(null, data._id);
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.response.data;
      toast.error(errorMessage);
    }
  };
// export const createCategoryReduxAction =
//   (formData, callBack) => async (dispatch, getState) => {
//     try {
//       const { data } = await axios.post("/api/categories/create", formData);
//       if (data.category) {
//         dispatch({
//           type: actionTypes.CREATE_CATEGORY,
//           payload: data.category,
//         });
//         callBack(data.category._id); // Callback with the new category ID
//       } else {
//         callBack(null); // Callback without ID indicates failure
//       }
//     } catch (error) {
//       const errorMessage =
//         error.response && error.response.data && error.response.data.message
//           ? error.response.data.message
//           : error.response.data;

//       toast.error(errorMessage);
//       callBack(null); // Ensure callback is called with null on error
//     }
//   };
export const createCategoryReduxAction =
  (formData, callBack) => async (dispatch, getState) => {
    try {
      // Log FormData contents for debugging
      for (let pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      const { data } = await axios.post("/api/categories/create", formData);

      // Log server response for debugging
      console.log("Server Response:", data);

      if (data.category) {
        dispatch({
          type: actionTypes.CREATE_CATEGORY,
          payload: data.category,
        });
        callBack(data.category._id); // Callback with the new category ID
      } else {
        callBack(null); // Callback without ID indicates failure
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.response.data;

      toast.error(errorMessage);
      callBack(null); // Ensure callback is called with null on error
    }
  };
