import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUserReduxAction } from "../../redux/actions/auth/userActions";
const AdminLinksComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logoutUserReduxAction());
    navigate("/sign-in");
  };
  return (
    <Navbar variant="dark" bg="dark">
      <Nav className="flex-column">
        <Nav.Link to="/admin/orders" as={Link}>
          Orders
        </Nav.Link>

        <Nav.Link as={Link} to="/admin/products">
          Products
        </Nav.Link>

        <Nav.Link to="/admin/categories" as={Link}>
          Categories
        </Nav.Link>

        <Nav.Link to="/admin/subs" as={Link}>
          Sub Categories
        </Nav.Link>

        <Nav.Link to="/admin/users" as={Link}>
          Users
        </Nav.Link>

        <Nav.Link to="/admin/chats" as={Link}>
          Chats
        </Nav.Link>

        <Nav.Link to="/admin/analytics" as={Link}>
          Analytics
        </Nav.Link>

        <Nav.Link to="/logout" as={Link} onClick={handleLogout}>
          Logout
        </Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default AdminLinksComponent;
