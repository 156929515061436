import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AdminLinksComponent from '../../components/admin/AdminLinksComponent';
import { useDispatch, useSelector } from 'react-redux';
import AdminChatRoomComponent from '../../components/admin/AdminChatRoomComponent';
import { setMessageReceivedReduxAction } from '../../redux/actions/chat/chatActions';

const AdminChatsPage = () => {
  const { chatRooms, socket, messageReceived } = useSelector(
    (state) => state.chat
  );
  const dispatch = useDispatch();
  const reduxDispatch = dispatch;

  return (
    <Row className='m-5'>
      <Col md={2}>
        <AdminLinksComponent />
      </Col>
      <Col md={10}>
        <Row>
          {Object.entries(chatRooms).map((chatRoom, idx) => (
            <AdminChatRoomComponent
              key={idx}
              chatRoom={chatRoom}
              socket={socket}
              messageReceived={messageReceived}
              roomIndex={idx + 1}
              socketUser={chatRoom[0]}
              setMessageReceivedReduxAction={setMessageReceivedReduxAction}
              reduxDispatch={reduxDispatch}
            />
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default AdminChatsPage;
