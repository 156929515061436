import axios from "axios";
import * as actionType from "../../constants/subs/subConstants";
import { toast } from "react-toastify";
export const getSubsReduxAction = () => async (dispatch) => {
  const { data } = await axios.get("/api/subs");
  dispatch({
    type: actionType.GET_SUBS,
    payload: data,
  });
};

export const insertNewSubReduxAction =
  (subData, callBack) => async (dispatch, getState) => {
    const { name, parentId } = subData; // Ensure destructuring is correct here
    const existingSubs = getState().getSubs.subs;

    try {
      const { data } = await axios.post("/api/subs/insert-new", {
        name,
        parentId,
      });
      if (data.subInserted) {
        dispatch({
          type: actionType.INSERT_NEW_SUB_CATEGORY,
          payload: [...existingSubs, data.subInserted],
        });
      }
      //execute callback with the new subId
      callBack(data.subInserted._id);
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message || "Unknown error";

      toast.error(errorMessage);
      // Additional error handling can be implemented here if necessary
    }
  };

export const deleteSubcategoryReduxAction =
  (id) => async (dispatch, getState) => {
    try {
      const existingSubs = getState().getSubs.subs;
      const subs = existingSubs.filter((x) => x._id !== id);

      const { data } = await axios.delete(`/api/subs/delete/${id}`);

      if (data.message === "Sub deleted successfully") {
        dispatch({ type: actionType.DELETE_NEW_SUB, payload: [...subs] });
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message || "Unknown error";

      toast.error(errorMessage);
    }
  };

export const createSubCategoryReduxAction =
  (formData, callBack) => async (dispatch, getState) => {
    try {
      for (let pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      const { data } = await axios.post("/api/subs/create", formData);

      console.log("Server Response:", data);

      if (data) {
        dispatch({
          type: actionType.CREATE_SUB_CATEGORY,
          payload: data,
        });
        callBack(data._id);
      } else {
        callBack(null);
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message || "Unknown error";

      toast.error(errorMessage);
      callBack(null);
    }
  };
