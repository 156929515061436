import React from 'react';
import { Form } from 'react-bootstrap';

const NewCategory = ({ newCategory, setNewCategory, handleAddNewCategory }) => {
  return (
    <div>
      <Form.Group
        className='mb-3'
        controlId='formBasicNewCategory'
      >
        <Form.Label className='text-warning'>
          Or create a new category (e.g. Women/Men/Textiles)
        </Form.Label>
        <Form.Control
          name='newCategory'
          type='text'
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          onKeyDown={handleAddNewCategory}
          placeholder='Enter a category name'
        />
      </Form.Group>
    </div>
  );
};

export default NewCategory;
