import React, { useState, useEffect, Suspense } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button } from "react-bootstrap";
import styled from "styled-components";
import { Card, Carousel } from "react-bootstrap";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";

// Styled component for the clickable cards
const StyledCard = styled(motion(Card))`
  cursor: pointer;
  margin-bottom: 1.5rem;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    border 0.3s ease-in-out;
  border: 2px solid #e0e0e0; /* Light grey border */
  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
    border-image: linear-gradient(to right, #6a11cb, #2575fc);
    border-image-slice: 1;
  }
`;

const AnimatedButton = styled(Button)`
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  &:hover,
  &:focus {
    background-color: #6a11cb;
    transform: scale(1.05);
  }
  border: 2px solid #e0e0e0; /* Light grey border */
  display: block;
  margin: 0 auto;
`;

const GradientText = styled(motion.h3)`
  background: linear-gradient(to right, #6a11cb, #2575fc);
  -webkit-background-clip: text;
  color: transparent;
`;

const ExpandedCard = styled(motion(Card))`
  width: 100%;
  margin: 0 auto;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  position: relative;
  height: 75vh;
  display: flex;
  align-items: center;
  background: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const StaticTextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #f6e6cb, #f9f9f9);
  color: white;
  z-index: 1;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
`;

const StaticImage = styled(motion.img)`
  width: 80%;
  max-height: 300px;
  object-fit: cover;
  margin-bottom: 1rem;
`;

const CarouselWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

const CustomCarousel = styled(Carousel)`
  width: 100%;
  height: 100%;
  .carousel-inner {
    height: 100%;
  }
  .carousel-item {
    height: 100%;
  }
  .carousel-item img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
  }
  .carousel-item img:hover {
    transform: scale(1.05);
  }
`;

const PlaceholderImage = styled.img`
  width: 100%;
  height: auto;
  filter: blur(8px);
`;

const ExitButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
`;

const containerVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.2,
    },
  },
  exit: { opacity: 0, scale: 0.8, transition: { duration: 0.5 } },
};

const childVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const slideVariants = {
  hidden: {
    x: "100%",
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
  exit: {
    x: "-100%",
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
};

const HomeCategoryCard = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const { data } = await axios.get("/api/categories/home");
        setCategories(data);
      } catch (error) {
        console.log({ Message: "Failed to fetch categories", Error: error });
      }
    };
    getCategories();
  }, []);
  const handleCardClick = (categoryName) => {
    setSelectedCategory(
      selectedCategory === categoryName ? null : categoryName
    );
    setCurrentSlide(0);
  };

  const handleSelect = (selectedIndex) => {
    setCurrentSlide(selectedIndex);
  };

  const handleExit = () => {
    setSelectedCategory(null);
  };

  useEffect(() => {
    if (selectedCategory) {
      const interval = setInterval(() => {
        const selectedCategoryImages = categories.find(
          (cat) => cat.name === selectedCategory
        ).images.length;
        setCurrentSlide((prevSlide) =>
          prevSlide === selectedCategoryImages - 1 ? 0 : prevSlide + 1
        );
      }, 3000); // change slide every 3 seconds
      return () => clearInterval(interval);
    }
  }, [selectedCategory, currentSlide, categories]);

  return (
    <Container fluid>
      <Row>
        {categories &&
          categories.map((category, idx) => (
            <Col
              key={idx}
              md={selectedCategory === category.name ? 12 : 4}
              xxl={selectedCategory === category.name ? 12 : 4}
              l={selectedCategory === category.name ? 12 : 4}
              className={
                selectedCategory && selectedCategory !== category.name
                  ? "d-none"
                  : ""
              }
            >
              <AnimatePresence mode="wait">
                {selectedCategory === category.name ? (
                  <Suspense fallback={<div>Loading...</div>}>
                    <ExpandedCard
                      variants={containerVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                    >
                      <Row style={{ width: "100%", height: "100%" }}>
                        <Col md={6}>
                          <StaticTextContainer>
                            <StaticImage
                              src={category.images[0].uri}
                              alt={category.name}
                              variants={childVariants}
                            />
                            <GradientText variants={childVariants}>
                              {category.name}
                            </GradientText>
                            <motion.p variants={childVariants}>
                              {category.description}
                            </motion.p>
                            <Link to={`/category/${category.name}`}>
                              <AnimatedButton variant="primary">
                                Discover {category.name}
                              </AnimatedButton>
                            </Link>
                          </StaticTextContainer>
                        </Col>
                        <Col md={6}>
                          <CarouselWrapper>
                            <CustomCarousel
                              activeIndex={currentSlide}
                              onSelect={handleSelect}
                              indicators={false}
                              nextIcon={
                                <span
                                  aria-hidden="true"
                                  className="carousel-control-next-icon"
                                />
                              }
                              prevIcon={
                                <span
                                  aria-hidden="true"
                                  className="carousel-control-prev-icon"
                                />
                              }
                            >
                              {category.images.map((image, idx) => (
                                <Carousel.Item key={idx}>
                                  <motion.img
                                    className="d-block w-100"
                                    src={image.uri}
                                    alt={category.name}
                                    variants={slideVariants}
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                  />
                                </Carousel.Item>
                              ))}
                            </CustomCarousel>
                          </CarouselWrapper>
                        </Col>
                      </Row>
                      <ExitButton variant="secondary" onClick={handleExit}>
                        Exit
                      </ExitButton>
                    </ExpandedCard>
                  </Suspense>
                ) : (
                  <Suspense
                    fallback={
                      <PlaceholderImage src="placeholder.jpg" alt="Loading" />
                    }
                  >
                    <StyledCard
                      onClick={() => handleCardClick(category.name)}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      transition={{ duration: 0.3 }}
                    >
                      {category.images.length > 0 && (
                        <Card.Img
                          variant="top"
                          src={category.images[0].uri}
                          alt={category.name}
                          loading="lazy"
                        />
                      )}
                      <Card.Body>
                        <Card.Title>{category.name}</Card.Title>
                      </Card.Body>
                    </StyledCard>
                  </Suspense>
                )}
              </AnimatePresence>
            </Col>
          ))}
      </Row>
    </Container>
  );
};

export default HomeCategoryCard;
