import React from 'react';
import UserCartDetailsPageComponent from './components/UserCartDetailsPageComponent';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import {
  addToCartReduxAction,
  removeProductFromCartAction,
} from '../../redux/actions/cart/cartActions';
const fetchUser = async (id) => {
  const { data } = await axios.get(`/api/users/profile/${id}`);
  return data;
};
const createUserOrder = async (orderData) => {
  const { data } = await axios.post('/api/orders/create', { ...orderData });
  return data;
};
const UserCartDetailsPage = () => {
  const { userInfo } = useSelector((state) => state.authUser);

  const { itemsCount, cartItems, cartSubtotal } = useSelector(
    (state) => state.cart
  );
  const dispatch = useDispatch();

  return (
    <UserCartDetailsPageComponent
      itemsCount={itemsCount}
      cartSubtotal={cartSubtotal}
      cartItems={cartItems}
      reduxDispatch={dispatch}
      addToCartReduxAction={addToCartReduxAction}
      removeProductFromCartAction={removeProductFromCartAction}
      userInfo={userInfo}
      fetchUser={fetchUser}
      createUserOrder={createUserOrder}
    />
  );
};

export default UserCartDetailsPage;
