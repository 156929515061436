import React from "react";
import { Nav, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaShoppingCart, FaUser } from "react-icons/fa"; // Importing icons from react-icons
import "../../css/nav-links.css"; // Ensure this path points to your actual CSS file

const NavLinks = ({ handleLogout, handleClose, inSidebar }) => {
  const { itemsCount } = useSelector((state) => state.cart);
  const { messageReceived } = useSelector((state) => state.chat);
  const { userInfo } = useSelector((state) => state.authUser);

  return (
    <Nav className={`${inSidebar ? "nav-sidebar" : "nav-links"}`}>
      {userInfo && userInfo.isAdmin ? (
        <Nav.Link
          as={Link}
          to="/admin/orders"
          className="position-relative person-link"
          onClick={handleClose}
        >
          <FaUser size={24} /> {/* Using FaUser from react-icons */}
          {inSidebar && <span className="ms-2">Admin</span>}
          {messageReceived && (
            <span className="position-absolute top-1 start-10 translate-middle p-2 bg-danger border border-light rounded-circle"></span>
          )}
        </Nav.Link>
      ) : userInfo && !userInfo.isAdmin ? (
        <NavDropdown
          title={<FaUser size={24} />}
          id="collapsible-nav-dropdown"
          className="person-link"
        >
          <NavDropdown.Item
            as={Link}
            to="/user/my-orders"
            onClick={handleClose}
          >
            My Orders
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/user" onClick={handleClose}>
            My profile
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={() => {
              handleLogout();
              handleClose();
            }}
          >
            Logout
          </NavDropdown.Item>
        </NavDropdown>
      ) : (
        <Nav.Link
          as={Link}
          to="/sign-in"
          className="person-link"
          onClick={handleClose}
        >
          <div className="person-icon-wrapper">
            <FaUser size={30} /> {/* Using FaUser from react-icons */}
            {inSidebar && (
              <span className="ms-2">
                <h3>Sign in</h3>
              </span>
            )}
          </div>
        </Nav.Link>
      )}
      <Nav.Link
        as={Link}
        to="/cart"
        className="cart-link"
        onClick={handleClose}
      >
        <div className="cart-icon-wrapper">
          <FaShoppingCart size={30} />
          {itemsCount > 0 && <Badge className="cart-badge">{itemsCount}</Badge>}
        </div>
        {inSidebar && (
          <span className="ms-3">
            <h3>Cart</h3>
          </span>
        )}
      </Nav.Link>
    </Nav>
  );
};

export default NavLinks;
