const initialState = {
  loading: false,
  success: false,
  error: null,
};

export const passwordResetReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PASSWORD_RESET_REQUEST":
      return { ...state, loading: true };
    case "PASSWORD_RESET_SUCCESS":
      return { ...state, loading: false, success: true };
    case "PASSWORD_RESET_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
