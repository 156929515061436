import React from "react";
import UserProfilePageComponent from "./components/UserProfilePageComponent";
import { updateUserReduxAction } from "../../redux/actions/auth/userActions";
import { useDispatch, useSelector } from "react-redux";

const UserProfilePage = () => {
  const { userInfo, isLoading, error } = useSelector((state) => state.authUser);
  const dispatch = useDispatch();
  const reduxDispatch = dispatch;

  return (
    <UserProfilePageComponent
      updateUserReduxAction={updateUserReduxAction}
      userInfo={userInfo}
      reduxDispatch={reduxDispatch}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default UserProfilePage;
