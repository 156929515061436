export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const CREATE_PRODUCT_ERROR = "CREATE_PRODUCT_ERROR";

export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const GET_INITIAL_PRODUCTS_REQUEST = "GET_INITIAL_PRODUCTS_REQUEST";
export const GET_INITIAL_PRODUCTS_SUCCESS = "GET_INITIAL_PRODUCTS_SUCCESS";
export const GET_INITIAL_PRODUCTS_FAIL = "GET_INITIAL_PRODUCTS_FAIL";

export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";

export const FILTER_PRODUCTS_REQUEST = "FILTER_PRODUCTS_REQUEST";
export const FILTER_PRODUCTS_SUCCESS = "FILTER_PRODUCTS_SUCCESS";
export const FILTER_PRODUCTS_FAIL = "FILTER_PRODUCTS_FAIL";

export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const RESET_FILTERS = "RESET_FILTERS";

// src/redux/actionTypes.js
export const SET_FILTERS = "SET_FILTERS";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";
export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";

export const FETCH_FILTERED_PRODUCTS_REQUEST =
  "FETCH_FILTERED_PRODUCTS_REQUEST";
export const FETCH_FILTERED_PRODUCTS_SUCCESS =
  "FETCH_FILTERED_PRODUCTS_SUCCESS";
export const FETCH_FILTERED_PRODUCTS_FAILURE =
  "FETCH_FILTERED_PRODUCTS_FAILURE";
export const RESET_FILTERING = "RESET_FILTERING";

// src/redux/constants/productConstants.js

export const FETCH_MEN_PRODUCTS_REQUEST = "FETCH_MEN_PRODUCTS_REQUEST";
export const FETCH_MEN_PRODUCTS_SUCCESS = "FETCH_MEN_PRODUCTS_SUCCESS";
export const FETCH_MEN_PRODUCTS_FAILURE = "FETCH_MEN_PRODUCTS_FAILURE";
export const SET_MEN_FILTERS = "SET_MEN_FILTERS";
