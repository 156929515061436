import React, { useEffect, useState } from "react";
import { Button, Nav, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "../../css/category-nav.css"; // Separate CSS file for CategoryNav

const CategoryNav = ({ className }) => {
  const { categories } = useSelector((state) => state.getCategories);
  const { subs } = useSelector((state) => state.getSubs);
  const [categorySubs, setCategorySubs] = useState({});
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Track window width
  const [activeCategory, setActiveCategory] = useState(null);
  const navigate = useNavigate();
  console.log(windowWidth);
  useEffect(() => {
    const subsByCategory = {};

    categories?.forEach((category) => {
      subsByCategory[category._id] = subs?.filter(
        (sub) => sub.parentId === category._id
      );
    });
    setCategorySubs(subsByCategory);
  }, [categories, subs]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Update window width on resize
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleDropdownToggle = (categoryId) => {
    setActiveDropdown((prevState) =>
      prevState === categoryId ? null : categoryId
    );
  };

  const handleCategoryClick = (categoryId, categoryName) => {
    setActiveCategory(categoryId); // Set active category
    navigate(`/${categoryName.toLowerCase()}`);
  };

  return (
    <Row className="category-nav-container m-3">
      <Nav className={`category-nav ${className}`}>
        <Link to="/shop">
          <Button className="shop-all-btn" variant="outline-light">
            <h3>Ready-made</h3>
          </Button>
        </Link>
        {categories?.map((category) => (
          <div
            className="nav-item"
            key={category._id}
            onMouseEnter={() =>
              windowWidth >= 768 && setActiveDropdown(category._id)
            }
            onMouseLeave={() => windowWidth >= 768 && setActiveDropdown(null)}
            onClick={() =>
              windowWidth < 768 &&
              setActiveDropdown((prev) =>
                prev === category._id ? null : category._id
              )
            }
          >
            <div
              className="nav-link-wrapper"
              onClick={() =>
                windowWidth >= 768
                  ? handleCategoryClick(category._id, category.name)
                  : null
              }
            >
              <span
                className={`nav-link ${
                  activeCategory === category._id ? "active" : ""
                }`}
              >
                <h3> {category.name}</h3>
              </span>
              {/* Show icons only for small screens */}
              {windowWidth < 768 && categorySubs[category._id]?.length > 0 && (
                <FontAwesomeIcon
                  icon={
                    activeDropdown === category._id
                      ? faChevronUp
                      : faChevronDown
                  }
                  className="dropdown-icon"
                />
              )}
            </div>
            {categorySubs[category._id]?.length > 0 &&
              activeDropdown === category._id && (
                <div className="nav-dropdown-menu">
                  {categorySubs[category._id].map((sub) => (
                    <Link
                      className="nav-dropdown-item"
                      to={`/sub/${sub.name.toLowerCase()}`}
                      key={sub._id}
                      onClick={() =>
                        windowWidth < 768 && setActiveDropdown(null)
                      }
                    >
                      <h4> {sub.name}</h4>
                    </Link>
                  ))}
                </div>
              )}
          </div>
        ))}
      </Nav>
    </Row>
  );
};

export default CategoryNav;
