import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Offcanvas, Button, Container, Row, Col, Card } from "react-bootstrap";
import {
  fetchFilteredMenProducts,
  setMenFilters,
} from "../../redux/actions/product/product";

import FilterSidebar from "../../components/filterQueryResultOptions/FilterSidebar";
import SearchFilter from "../../components/filterQueryResultOptions/SearchFilter";
import SortFilter from "../../components/filterQueryResultOptions/SortFilter";
import PaginationComponent from "../../components/filterQueryResultOptions/Pagination";
import ProductForListComponent from "../../components/ProductForListComponent";
import "../../css/men-page.css";
import "../../css/shared-styles.css";
import axios from "axios";

const MenPage = () => {
  const dispatch = useDispatch();
  const {
    products,
    filters,
    minPrice,
    maxPrice,
    attrs,
    subCategories,
    loading,
    error,
    pages,
    page,
  } = useSelector((state) => state.menProducts);

  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [sortBy, setSortBy] = useState(filters.sortBy || "relevance");
  const [searchTerm, setSearchTerm] = useState(filters.searchTerm || "");
  const [subs, setSubs] = useState([]);
  const [loadingSubs, setLoadingSubs] = useState(false);
  const [errorSubs, setErrorSubs] = useState(null);

  useEffect(() => {
    dispatch(fetchFilteredMenProducts());
  }, [dispatch]);

  useEffect(() => {
    const fetchMenSubs = async () => {
      try {
        const { data } = await axios.get("/api/subs/men/category");
        setSubs(data);
        setLoadingSubs(false);
      } catch (error) {
        setErrorSubs(
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        );
        setLoadingSubs(false);
      }
    };
    fetchMenSubs();
  }, []);

  const handleFilterChange = useCallback(
    (newFilters) => {
      dispatch(setMenFilters(newFilters));
      dispatch(fetchFilteredMenProducts());
    },
    [dispatch]
  );

  const handleResetFilters = useCallback(() => {
    const resetFilters = {
      minPrice,
      maxPrice,
      page: 1,
      limit: 10,
      attrs: {},
      subCategory: [],
      sortBy: "relevance",
      searchTerm: "",
    };
    dispatch(setMenFilters(resetFilters));
    setSortBy("relevance");
    setSearchTerm("");
    dispatch(fetchFilteredMenProducts());
  }, [dispatch, minPrice, maxPrice]);

  const handleSortChange = (selected) => {
    const updatedSortBy = selected.value;
    setSortBy(updatedSortBy);
    dispatch(setMenFilters({ ...filters, sortBy: updatedSortBy }));
    dispatch(fetchFilteredMenProducts());
  };

  const handleSearchTermChange = (term) => {
    setSearchTerm(term);
    dispatch(setMenFilters({ ...filters, searchTerm: term }));
    dispatch(fetchFilteredMenProducts());
  };

  const setPage = (pageNumber) => {
    dispatch(setMenFilters({ ...filters, page: pageNumber }));
    dispatch(fetchFilteredMenProducts());
  };

  return (
    <Container fluid>
      <Row className="g-5 mb-4 men-top-card">
        {subs.map((s) => (
          <Col
            key={s._id}
            xs={3}
            sm={6}
            md={3}
            lg={3}
            xl={2}
            className="d-flex justify-content-center"
          >
            {" "}
            <Card className="card-masculine">
              <Card.Img
                variant="top"
                src={
                  s.images && s.images.length > 0
                    ? s.images[0].uri
                    : "placeholder-image-url"
                }
                alt={s.name}
              />
              <Card.Body className="men-card-body">
                <Card.Title className="men-card-title">
                  <h2>{s.name}</h2>
                </Card.Title>
                <Card.Link href={`/sub/${s.name}`}>
                  <Button className="card-button">Explore</Button>
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <Row>
        {/* Sidebar only visible on screens with width 1025px and above */}
        <Col className="filter-sidebar d-none d-lg-block" lg={3}>
          <FilterSidebar
            filters={filters}
            minPrice={minPrice}
            maxPrice={maxPrice}
            attrs={attrs}
            subCategories={subCategories}
            onFilterChange={handleFilterChange}
            onResetFilters={handleResetFilters}
            fetchProducts={fetchFilteredMenProducts}
          />
        </Col>

        <Col lg={9}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <SortFilter sortBy={sortBy} onSortChange={handleSortChange} />

            {/* Hide search filter on screens with width less than 1025px */}
            <div className="d-none d-lg-block search-filter-wrapper">
              <SearchFilter
                searchTerm={searchTerm}
                onSearchTermChange={handleSearchTermChange}
              />
            </div>

            {/* Show filter button on screens with width 1024px and below */}
            <Button
              className="filter-button-wrapper d-lg-none"
              onClick={() => setShowOffCanvas(true)}
            >
              FILTER
            </Button>
          </div>

          {loading ? (
            <div>Loading...</div>
          ) : products.length > 0 ? (
            <>
              <Row className="product-list loaded">
                {products.map((product) => (
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    key={product._id}
                    className="d-flex justify-content-center"
                  >
                    <ProductForListComponent product={product} />
                  </Col>
                ))}
              </Row>
              <PaginationComponent
                page={page}
                pages={pages}
                setPage={setPage}
              />
            </>
          ) : (
            <div>No products found</div>
          )}
        </Col>
      </Row>

      <Offcanvas
        show={showOffCanvas}
        onHide={() => setShowOffCanvas(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="canvas-title">
            Filter Products
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <FilterSidebar
            filters={filters}
            minPrice={minPrice}
            maxPrice={maxPrice}
            attrs={attrs}
            subCategories={subCategories}
            onFilterChange={handleFilterChange}
            onResetFilters={handleResetFilters}
            fetchProducts={fetchFilteredMenProducts}
            isOffCanvas
          />
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
};

export default MenPage;
