import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

const AdminEditUserPageComponent = ({ adminUpdateUser, adminFetchUser }) => {
  const [validated, setValidated] = useState(false);
  const [user, setUser] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    adminFetchUser(id)
      .then((res) => {
        setUser(res);
        setIsAdmin(res.isAdmin);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Unknown error occurred!";
        toast.error(errorMessage);
      });
  }, [adminFetchUser, id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget.elements;

    const name = form.name.value;
    const lastName = form.lastName.value;
    const email = form.email.value;

    if (e.currentTarget.checkValidity() === true) {
      adminUpdateUser(id, name, lastName, email, isAdmin)
        .then((res) => {
          navigate("/admin/users");
        })
        .catch((error) => {
          const errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Unknown error occurred!";
          toast.error(errorMessage);
        });
    }
    setValidated(true);
  };
  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col md={2}>
          <Link to="/admin/users">
            <Button className="btn btn-info my-3">Go back</Button>
          </Link>
        </Col>
        <Col md={10}>
          {loading ? <div>Loading ...</div> : ""}
          <h1>Edit User</h1>
          <Form onSubmit={handleSubmit} validated={validated}>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                name="name"
                type="text"
                required
                defaultValue={user.name}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="mb-3">Last Name</Form.Label>
              <Form.Control
                name="lastName"
                type="text"
                required
                defaultValue={user.lastName}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                type="email"
                required
                defaultValue={user.email}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                name="isAdmin"
                type="checkbox"
                label="Is Admin"
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
              />
            </Form.Group>
            <Button type="submit" variant="primary">
              Update User
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminEditUserPageComponent;
