import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import { Row, Col, Container, ListGroup, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AddedToCartMessageComponent from "../../components/AddedToCartMessageComponent";
import ProductImageZoom from "../../components/product/ProductImageZoom";
import MetaComponent from "../../components/meta/MetaComponent";
import "../../product-details.css";

const ProductDetailsPageComponent = ({
  fetchProduct,
  reduxDispatch,
  addToCartReduxAction,
}) => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState("");
  const [showCartMessage, setShowCartMessage] = useState(false);

  useEffect(() => {
    fetchProduct(id)
      .then((res) => {
        setProduct(res);
      })
      .catch((error) => {
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message || "Unknown error";
        toast.error(errorMessage);
      });
  }, [fetchProduct, id]);

  const addToCartHandler = () => {
    reduxDispatch(addToCartReduxAction(id, quantity));
    setShowCartMessage(true);
  };

  return (
    <>
      <MetaComponent title={product.name} description={product.description} />
      <Container fluid className="product-details-container">
        <AddedToCartMessageComponent
          showCartMessage={showCartMessage}
          setShowCartMessage={setShowCartMessage}
        />
        <Row className="mt-5">
          <Col md={4}>
            <ProductImageZoom product={product} />
          </Col>
          <Col md={8}>
            <Row>
              <Col md={8}>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <h1>{product.name}</h1>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <p>{product.description}</p>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col md={4}>
                <ListGroup>
                  <ListGroup.Item>In Stock: {product.count}</ListGroup.Item>
                  <ListGroup.Item className="mt-3">
                    Price: <span className="fw-bold"> £{product.price}</span>
                  </ListGroup.Item>
                  <ListGroup.Item className="mt-3">
                    Quantity:
                    <Form.Select
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                      size="lg"
                    >
                      <option value="">Please select</option>
                      {[...Array(product.count).keys()].map((x, idx) => (
                        <option key={idx} value={x + 1}>
                          {x + 1}
                        </option>
                      ))}
                    </Form.Select>
                  </ListGroup.Item>
                  <ListGroup.Item className="mt-3">
                    <Button
                      onClick={addToCartHandler}
                      variant="primary"
                      disabled={!quantity || quantity === ""}
                    >
                      Add to cart
                    </Button>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProductDetailsPageComponent;
