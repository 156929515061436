import axios from "axios";
import * as actionType from "../../constants/products/product";
import { toast } from "react-toastify";

import {
  FETCH_MEN_PRODUCTS_REQUEST,
  FETCH_MEN_PRODUCTS_SUCCESS,
  FETCH_MEN_PRODUCTS_FAILURE,
  SET_MEN_FILTERS,
} from "../../constants/products/product";
export const getProductReduxAction = (id) => async (dispatch) => {
  const { data } = await axios.get(`/api/products/get-one/${id}`);
  dispatch({
    type: actionType.GET_PRODUCTS,
    payload: data,
  });
};

export const createProductReduxAction =
  (formData, callBack) => async (dispatch) => {
    try {
      const response = await axios.post("/api/products/admin/create", formData);
      dispatch({
        type: actionType.CREATE_PRODUCT,
        payload: response.data,
      });
      if (callBack) callBack(null, response.data); // Pass data back in callback if needed
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message || "Unknown error";

      toast.error(errorMessage);
      if (callBack) callBack(errorMessage); // Pass error message back in callback if needed

      // Optionally, handle errors with a dedicated action to update your application state
      dispatch({
        type: actionType.CREATE_PRODUCT_ERROR,
        payload: errorMessage,
      });
    }
  };

export const fetchAttributes = () => async (dispatch) => {
  dispatch({
    type: "FETCH_ATTRIBUTES_REQUEST",
  });
  try {
    const { data } = await axios.get("/api/products/attributes");
    dispatch({
      type: "FETCH_ATTRIBUTES_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "FETCH_ATTRIBUTES_FAILURE",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const setFilters = (filters) => ({
  type: "SET_FILTERS",
  payload: filters,
});

export const resetFilters = () => ({
  type: "RESET_FILTERS",
});

export const fetchProducts = (filters) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_PRODUCTS_REQUEST" });

    const { data } = await axios.get("/api/products", { params: filters });

    dispatch({
      type: "FETCH_PRODUCTS_SUCCESS",
      payload: {
        products: data.products,
        page: data.page,
        pages: data.pages,
        totalProducts: data.totalProducts,
      },
    });
  } catch (error) {
    dispatch({
      type: "FETCH_PRODUCTS_FAILURE",
      payload: error.message,
    });
  }
};

//Men Products

// Action to fetch filtered men's products

export const setMenFilters = (filters) => ({
  type: SET_MEN_FILTERS,
  payload: filters,
});

export const fetchFilteredMenProducts = () => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_MEN_PRODUCTS_REQUEST });

    const { filters } = getState().menProducts;
    const { data } = await axios.get("/api/products/men", {
      params: filters, // Pass all filters including searchTerm and sortBy as query params
    });

    dispatch({
      type: FETCH_MEN_PRODUCTS_SUCCESS,
      payload: {
        products: data.products,
        page: data.page,
        pages: data.pages,
        minPrice: data.minPrice,
        maxPrice: data.maxPrice,
        attrs: data.attrs,
        subCategories: data.subCategories,
        filters, // Maintain current filters in the state
      },
    });
    console.log("Men products data", data);
  } catch (error) {
    dispatch({
      type: FETCH_MEN_PRODUCTS_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
