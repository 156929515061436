import React from 'react';
import AdminEditCategoryPageComponent from './components/AdminEditCategoryPageComponent';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { editCategoryReduxAction } from '../../redux/actions/category/categoryActions';

const fetchCategoryApiRequest = async (id) => {
  const { data } = await axios.get(`/api/categories/get-one/${id}`);
  return data;
};

const AdminEditCategoryPage = () => {
  const dispatch = useDispatch();
  const reduxDispatch = dispatch;
  return (
    <AdminEditCategoryPageComponent
      fetchCategoryApiRequest={fetchCategoryApiRequest}
      editCategoryReduxAction={editCategoryReduxAction}
      reduxDispatch={reduxDispatch}
    />
  );
};

export default AdminEditCategoryPage;
