import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
const UserOrdersPageComponent = ({ fetchUserOrders, userInfo }) => {
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    fetchUserOrders(userInfo._id).then((res) => {
      if (res) {
        setOrders(res);
        console.log(res);
      } else {
        setOrders("");
      }
    });
  }, [fetchUserOrders, userInfo._id]);

  return (
    <Container>
      <Row className="mt-5 ">
        <Col md={12}>
          <h1 className="text-primary">My Orders</h1>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>User</th>
                <th>Date</th>
                <th>Total</th>
                <th>Delivered</th>
                <th>Order Details</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, idx) => (
                <tr key={idx}>
                  <td>#</td>
                  <td>you</td>
                  <td>
                    {order.createdAt ? order.createdAt.substring(0, 10) : "N/A"}
                  </td>
                  <td>£{order.orderTotal.cartSubtotal}</td>
                  <td>
                    {order.isDelivered ? (
                      <i className="bi bi-check-lg text-success"></i>
                    ) : (
                      <i className="bi bi-x-lg text-danger"></i>
                    )}
                  </td>
                  <td>
                    <Link to={`/user/order-details/${order._id}`}>
                      go to order
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default UserOrdersPageComponent;
