import React from "react";
import SignInPageComponent from "./components/SignInPageComponent";

import { useDispatch, useSelector } from "react-redux";
import { signInUserReduxAction } from "../redux/actions/auth/userActions";

const SignInPage = () => {
  const { userInfo, error, isLoading } = useSelector((state) => state.authUser);
  const dispatch = useDispatch();
  const reduxDispatch = dispatch;
  return (
    <SignInPageComponent
      reduxDispatch={reduxDispatch}
      signInUserReduxAction={signInUserReduxAction}
      userInfo={userInfo}
      error={error}
      isLoading={isLoading}
    />
  );
};

export default SignInPage;
