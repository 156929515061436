import React, { useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../home-carousel.css"; // Ensure this path matches your file structure

const HomeProductCarousel = ({ products }) => {
  useEffect(() => {
    console.log("Products:", products);
  }, [products]);

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} carousel-arrow slick-next`}
        style={{ ...style }}
        onClick={onClick}
      >
        <span>&#10095;</span>
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} carousel-arrow slick-prev`}
        style={{ ...style }}
        onClick={onClick}
      >
        <span>&#10094;</span>
      </div>
    );
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Container className="product-carousel-container">
      <Row>
        <Col>
          {products && products.length > 0 ? (
            <Slider {...settings}>
              {products &&
                products.map((product, idx) => (
                  <Card className="carousel-card" key={idx}>
                    <Card.Img
                      className="carousel-card-img"
                      variant="top"
                      src={product.images && product.images[0]?.uri}
                      alt={product.name}
                    />
                    <Card.Body className="carousel-card-body">
                      <h5 className="carousel-card-title">{product.name}</h5>
                      <Row>
                        <Col className="d-flex justify-content-center">
                          <Link to={`/product-details/${product._id}`}>
                            <Button className="carousel-card-button">
                              Explore
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ))}
            </Slider>
          ) : (
            <p>No products found</p>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default HomeProductCarousel;
