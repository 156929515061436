import React from 'react';
import AdminAnalyticsPageComponent from './components/AdminAnalyticsPageComponent';
import axios from 'axios';
import socketIOClient from 'socket.io-client';
const fetchOrdersForFirstDate = async (abortCtrl, firstDateToCompare) => {
  const { data } = await axios.get(
    '/api/orders/admin/analysis/' + firstDateToCompare,
    {
      signal: abortCtrl.signal,
    }
  );
  return data;
};
const fetchOrdersForSecondDate = async (abortCtrl, secondDateToCompare) => {
  const { data } = await axios.get(
    '/api/orders/admin/analysis/' + secondDateToCompare,
    {
      signal: abortCtrl.signal,
    }
  );
  return data;
};

const AdminAnalyticsPage = () => {
  return (
    <AdminAnalyticsPageComponent
      fetchOrdersForFirstDate={fetchOrdersForFirstDate}
      fetchOrdersForSecondDate={fetchOrdersForSecondDate}
      socketIOClient={socketIOClient}
    />
  );
};

export default AdminAnalyticsPage;
