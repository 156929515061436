import React from "react";
import AdminUsersPageComponent from "./components/AdminUsersPageComponent";
import axios from "axios";
import { toast } from "react-toastify";

const fetchUsers = async (controller) => {
  try {
    const { data } = await axios.get("/api/users/admin", {
      signal: controller.signal,
    });
    return data;
  } catch (error) {
    error.response && error.response.data && error.response.data.message
      ? error.response.data.message
      : error.message || "Unknown error";
    toast.error(errorMessage);
  }
};

const deleteUser = async (userId) => {
  const { data } = await axios.delete(`/api/users/admin/delete/${userId}`);
  return data;
};
const AdminUsersPage = () => {
  return (
    <AdminUsersPageComponent fetchUsers={fetchUsers} deleteUser={deleteUser} />
  );
};

export default AdminUsersPage;
