import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { useParams } from "react-router-dom";
import ProductCard from "../../components/cards/ProductCard";

const SubCategoryPage = () => {
  const { subName } = useParams();
  const [loading, setLoading] = useState(true);
  const [sub, setSub] = useState({});
  const [products, setProducts] = useState([]);

  const fetchSubProductsApiRequest = async (name) => {
    try {
      const { data } = await axios.get(`/api/subs/${name}/products`);
      console.log(data);
      return data;
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message || "Unknown error";

      toast.error(errorMessage);
      return null;
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchSubProductsApiRequest(subName)
      .then((res) => {
        if (res) {
          setProducts(res.products);
          setSub(res.sub);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [subName]);

  return (
    <Container>
      <Row>
        <Col>
          {loading ? (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <Row>
              <Col>
                <h1 className="text-primary">{sub && sub.name}</h1>
              </Col>
            </Row>
          )}

          <Row>
            {products &&
              products.map((product) => (
                <Col xs={12} sm={12} md={6} lg={4} key={product._id}>
                  <ProductCard product={product} />
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SubCategoryPage;
