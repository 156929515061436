import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";
const AdminAnalyticsPageComponent = ({
  fetchOrdersForFirstDate,
  fetchOrdersForSecondDate,
  socketIOClient,
}) => {
  const [firstDateToCompare, setFirstDateToCompare] = useState(
    new Date().toISOString().substring(0, 10)
  );
  var previousDay = new Date();
  previousDay.setDate(previousDay.getDate() - 1);
  const [secondDateToCompare, setSecondDateToCompare] = useState(
    new Date(previousDay).toISOString().substring(0, 10)
  );

  const [dataForFirstSet, setDataForFirstSet] = useState([]);
  const [dataForSecondSet, setDataForSecondSet] = useState([]);

  useEffect(() => {
    const socket = socketIOClient();
    const today = new Date().toDateString();

    const updateDataSet = (newOrder, setDate, dateToCompare) => {
      setDate((prev) => {
        const orderDate = new Date(newOrder.createdAt).toLocaleString("en-GB", {
          hour: "numeric",
          hour12: false,
          timeZone: "Europe/London",
        });

        // Find if entry for this hour already exists
        const index = prev.findIndex((data) => data.name === orderDate);
        if (index > -1) {
          // If exists, create a new object with updated subtotal and spread into new array
          const updatedData = {
            ...prev[index],
            [dateToCompare]:
              prev[index][dateToCompare] + newOrder.orderTotal.cartSubtotal,
          };
          return [
            ...prev.slice(0, index),
            updatedData,
            ...prev.slice(index + 1),
          ];
        } else {
          // If not, add a new entry to the array
          return [
            ...prev,
            {
              name: orderDate,
              [dateToCompare]: newOrder.orderTotal.cartSubtotal,
            },
          ];
        }
      });
    };

    const handler = (newOrder) => {
      if (new Date(newOrder.createdAt).toDateString() === today) {
        if (today === new Date(firstDateToCompare).toDateString()) {
          updateDataSet(newOrder, setDataForFirstSet, firstDateToCompare);
        } else if (today === new Date(secondDateToCompare).toDateString()) {
          updateDataSet(newOrder, setDataForSecondSet, secondDateToCompare);
        }
      }
    };

    socket.on("newOrder", handler);
    return () => socket.off("newOrder", handler);
  }, [
    socketIOClient,
    setDataForFirstSet,
    setDataForSecondSet,
    firstDateToCompare,
    secondDateToCompare,
  ]);

  useEffect(() => {
    const abortCtrl = new AbortController();
    fetchOrdersForFirstDate(abortCtrl, firstDateToCompare)
      .then((data) => {
        let orderSum = 0;
        const orders = data.map((order) => {
          orderSum += order.orderTotal.cartSubtotal;
          var date = new Date(order.createdAt).toLocaleString("en-GB", {
            hour: "numeric",
            hour12: true,
            timeZone: "Europe/London",
          });
          return { name: date, [firstDateToCompare]: orderSum };
        });
        setDataForFirstSet(orders);
      })
      .catch((error) => {
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message || "Unknown error";
        toast.error(errorMessage);
      });

    fetchOrdersForSecondDate(abortCtrl, secondDateToCompare)
      .then((data) => {
        let orderSum = 0;
        const orders = data.map((order) => {
          orderSum += order.orderTotal.cartSubtotal;
          var date = new Date(order.createdAt).toLocaleString("en-GB", {
            hour: "numeric",
            hour12: true,
            timeZone: "Europe/London",
          });
          return { name: date, [secondDateToCompare]: orderSum };
        });
        setDataForSecondSet(orders);
      })
      .catch((error) => {
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message || "Unknown error";
        toast.error(errorMessage);
      });
    return () => abortCtrl.abort();
  }, [
    firstDateToCompare,
    secondDateToCompare,
    fetchOrdersForFirstDate,
    fetchOrdersForSecondDate,
  ]);

  const firstDateHandler = (e) => {
    setFirstDateToCompare(e.target.value);
  };

  const secondDateHandler = (e) => {
    setSecondDateToCompare(e.target.value);
  };

  return (
    <Row className="m-5">
      <Col md={2}>
        <AdminLinksComponent />
      </Col>
      <Col md={10}>
        <h1>
          Black Friday Cumulative Revenue {firstDateToCompare} VS{" "}
          {secondDateToCompare}
        </h1>
        <Form.Group controlId="firstDateToCompare">
          <Form.Label>Select First Date To Compare</Form.Label>
          <Form.Control
            onChange={firstDateHandler}
            type="date"
            name="firstDateToCompare"
            placeholder="First Date To Compare"
            defaultValue={firstDateToCompare}
          />
        </Form.Group>
        <br />
        <Form.Group controlId="secondDateToCompare">
          <Form.Label>Select Second Date To Compare</Form.Label>
          <Form.Control
            onChange={secondDateHandler}
            type="date"
            name="secondDateToCompare"
            placeholder="Second Date To Compare"
            defaultValue={secondDateToCompare}
          />
        </Form.Group>
        <ResponsiveContainer
          width="100%"
          height={500}
          key={`${firstDateToCompare}-${secondDateToCompare}`}
        >
          <LineChart
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              label={{
                value: "TIME",
                offset: 50,
                position: "insideBottomRight",
              }}
              allowDuplicatedCategory={false}
            />
            <YAxis
              label={{
                value: "REVENUE £",
                angle: -90,
                position: "insideLeft",
              }}
            />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            {dataForFirstSet.length > dataForSecondSet.length ? (
              <>
                <Line
                  data={dataForFirstSet}
                  type="monotone"
                  dataKey={firstDateToCompare}
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                  strokeWidth={4}
                />
                <Line
                  data={dataForSecondSet}
                  type="monotone"
                  dataKey={secondDateToCompare}
                  stroke="#82ca9d"
                  strokeWidth={4}
                />
              </>
            ) : (
              <>
                <Line
                  data={dataForSecondSet}
                  type="monotone"
                  dataKey={secondDateToCompare}
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                  strokeWidth={4}
                />
                <Line
                  data={dataForFirstSet}
                  type="monotone"
                  dataKey={firstDateToCompare}
                  stroke="#82ca9d"
                  strokeWidth={4}
                />
              </>
            )}
          </LineChart>
        </ResponsiveContainer>
      </Col>
    </Row>
  );
};

export default AdminAnalyticsPageComponent;
