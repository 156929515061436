import React from "react";
import { Button, Card } from "react-bootstrap";
import "../../css/product-card.css"; // Import the CSS file
import { Link } from "react-router-dom";

const ProductCard = ({ product }) => {
  const { images, name, price } = product;

  const locale = "en-GB"; // This can be dynamic based on user preferences or location
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "GBP",
  });
  const formattedPrice = formatter.format(price);
  return (
    <div>
      <Card className="product-for-list-card" style={{ width: "18rem" }}>
        <Card.Img
          crossOrigin="anonymous"
          variant="top"
          className="card-img-top"
          src={images[0] ? images[0].uri : ""}
        />

        <Card.Body className="product-for-list-card-body">
          <Card.Title className="card-title">{name}</Card.Title>

          <Card.Text className="card-text h4 mt-auto">
            {formattedPrice}{" "}
          </Card.Text>
          <Link to={`/product-details/${product._id}`}>
            <Button variant="primary">See product</Button>
          </Link>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProductCard;
