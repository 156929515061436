import { Button, Col, Form, Row } from 'react-bootstrap';

const AttributeSelectors = ({
  categoryChosen,
  attributeKey,
  handleAttributeKeyChange,
  attributesFromDatabase,
  attributeValue,
  handleAttributeValueChange,
  addAttributeToTable,
}) => {
  return (
    <div>
      {categoryChosen && (
        <>
          <Row className='mt-5'>
            <Col md={6}>
              <Form.Group
                className='mb-3'
                controlId='formBasicAttributes'
              >
                <Form.Label>Choose attribute and set value</Form.Label>
                <Form.Select
                  name='attributeKey'
                  value={attributeKey}
                  onChange={handleAttributeKeyChange}
                >
                  <option value=''>Choose attribute</option>
                  {attributesFromDatabase.map((attr) => (
                    <option
                      key={attr._id}
                      value={attr.key}
                    >
                      {attr.key}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group
                className='mb-3'
                controlId='formBasicAttributeValue'
              >
                <Form.Label>Attribute value</Form.Label>
                <div>
                  {attributeValue.map((item, index) => (
                    <Form.Check
                      key={index}
                      value={item.value}
                      label={item.value}
                      type='checkbox'
                      checked={item.checked}
                      onChange={handleAttributeValueChange}
                    />
                  ))}
                </div>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Button onClick={addAttributeToTable}>
                Add to attributes table
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default AttributeSelectors;
