import * as actionTypes from '../../constants/products/cartConstants';

const CART_INITIAL_STATE = {
  cartItems: [],
  itemsCount: 0,
  cartSubtotal: 0,
};

export const cartReducer = (state = CART_INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ADD_TO_CART: {
      const { productID, quantity, price } = action.payload;
      const numericQuantity = Number(quantity);
      const numericPrice = Number(price);

      // Find if the product already exists in the cart
      const existingProductIndex = state.cartItems.findIndex(
        (item) => item.productID === productID
      );

      if (existingProductIndex !== -1) {
        // Product exists, set its quantity to the specified value
        const newCartItems = state.cartItems.map((item, index) => {
          if (index === existingProductIndex) {
            return {
              ...item,
              quantity: numericQuantity, // Set quantity directly
              price: numericPrice, // Update price if needed
            };
          }
          return item;
        });

        // Recalculate itemsCount and cartSubtotal
        return {
          ...state,
          cartItems: newCartItems,
          itemsCount: newCartItems.reduce(
            (total, item) => total + item.quantity,
            0
          ),
          cartSubtotal: newCartItems.reduce(
            (total, item) => total + item.price * item.quantity,
            0
          ),
        };
      } else {
        // Product does not exist, add as a new item
        const newItem = {
          ...action.payload,
          quantity: numericQuantity,
          price: numericPrice,
        };
        const newCartItems = [...state.cartItems, newItem];

        return {
          ...state,
          cartItems: newCartItems,
          itemsCount: newCartItems.reduce(
            (total, item) => total + item.quantity,
            0
          ),
          cartSubtotal: newCartItems.reduce(
            (total, item) => total + item.price * item.quantity,
            0
          ),
        };
      }
    }

    case actionTypes.CLEAR_PRODUCT_FROM_CART:
      const updatedCartItems = state.cartItems.filter(
        (item) => item.productID !== action.payload
      );
      return {
        ...state,
        cartItems: updatedCartItems,
        itemsCount: updatedCartItems.length, // Update to reflect actual count of items
        cartSubtotal: updatedCartItems.reduce(
          (total, item) => total + item.price * item.quantity,
          0
        ),
      };

    default:
      return state;
  }
};
