import React from "react";
import { Pagination } from "react-bootstrap";
import "../../css/shared-styles.css"; // Import shared styles

const PaginationComponent = ({ page, pages, setPage }) => {
  if (pages <= 1) return null;

  return (
    <Pagination className="justify-content-center">
      {[...Array(pages).keys()].map((x) => (
        <Pagination.Item
          key={x + 1}
          active={x + 1 === page}
          onClick={() => setPage(x + 1)}
        >
          {x + 1}
        </Pagination.Item>
      ))}
    </Pagination>
  );
};

export default PaginationComponent;
