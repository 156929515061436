import React from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const AdminCategoriesPageComponent = ({
  categories,
  deleteCategoryReduxAction,
  reduxDispatch,
}) => {
  const handleDeleteCategory = (categoryId) => {
    if (window.confirm("Are you sure ")) {
      const callback = (id) => {
        toast.success("category deleted successfully");
      };
      const errorCallback = (error) => {
        toast.error("Failed to delete category", `${error}`);
      };
      reduxDispatch(
        deleteCategoryReduxAction(categoryId, callback, errorCallback)
      );
    }
  };

  return (
    <Container>
      <Row>
        <Col md={3}>
          <AdminLinksComponent />
        </Col>
        <Col md={9} className="mt-5">
          <Row>
            <Col md={6}>
              <h1>Categories</h1>
            </Col>
            <Col md={6}>
              <Link to="/admin/category-create">
                <Button>Create new category</Button>
              </Link>
            </Col>
          </Row>

          <Table responsive hover striped bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>ID</th>
                <th>Delete</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {categories &&
                categories.map((c, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{c.name}</td>
                    <td>{c._id}</td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteCategory(c._id)}
                      >
                        <i className="bi bi-trash3 "></i>
                      </Button>
                    </td>
                    <td>
                      <Link to={`/admin/category-edit/${c._id}`}>
                        <Button>
                          <i className="bi bi-pencil-square"></i>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminCategoriesPageComponent;
