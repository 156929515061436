import React, { useState, useEffect, useCallback } from "react";
import { Range } from "react-range";
import Select from "react-select";
import { Button, Spinner, Row, Col, Container } from "react-bootstrap";
import "../../css/shared-styles.css"; // Import shared styles

const FilterSidebar = ({
  filters,
  minPrice,
  maxPrice,
  attrs,
  subCategories,
  onFilterChange,
  onResetFilters,
  fetchProducts,
  isOffCanvas = false, // Optional prop to adjust for Offcanvas usage
}) => {
  const [isApplying, setIsApplying] = useState(false);
  const [localPriceRange, setLocalPriceRange] = useState([minPrice, maxPrice]);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  useEffect(() => {
    const newMin = Math.max(filters.minPrice || minPrice, minPrice);
    const newMax = Math.min(filters.maxPrice || maxPrice, maxPrice);
    setLocalPriceRange([newMin, newMax]);
  }, [minPrice, maxPrice, filters.minPrice, filters.maxPrice]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const updateFilters = useCallback(
    (newFilters) => {
      onFilterChange({ ...filters, ...newFilters });
      fetchProducts();
    },
    [filters, onFilterChange, fetchProducts]
  );

  const onPriceChange = useCallback(
    (values) => {
      setLocalPriceRange(values);
      updateFilters({
        minPrice: values[0],
        maxPrice: values[1],
        attrs: selectedAttributes,
        subCategory: selectedSubCategories,
      });
    },
    [selectedAttributes, selectedSubCategories, updateFilters]
  );

  const getAttributeOptions = useCallback(
    (key) => {
      const allOptions = attrs.find((attr) => attr.key === key)?.value || [];
      const selectedValues = selectedAttributes[key] || [];
      return allOptions
        .filter((option) => !selectedValues.includes(option))
        .map((value) => ({
          label: value,
          value,
          key: `${key}-${value}`,
        }));
    },
    [attrs, selectedAttributes]
  );

  const handleAttributeChange = useCallback(
    (key, selectedOptions) => {
      const selectedValues = selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [];

      setSelectedAttributes((prevState) => {
        const updatedAttributes = { ...prevState, [key]: selectedValues };
        updateFilters({
          ...filters,
          attrs: updatedAttributes,
        });
        return updatedAttributes;
      });
    },
    [filters, updateFilters]
  );

  const handleSubCategoryChange = useCallback(
    (selectedOptions) => {
      const selectedValues = selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [];
      setSelectedSubCategories(selectedValues);
      updateFilters({
        subCategory: selectedValues,
        minPrice: localPriceRange[0],
        maxPrice: localPriceRange[1],
        attrs: selectedAttributes,
      });
    },
    [localPriceRange, selectedAttributes, updateFilters]
  );

  const resetFilters = useCallback(() => {
    setLocalPriceRange([minPrice, maxPrice]);
    setSelectedSubCategories([]);
    setSelectedAttributes({});
    onResetFilters();
    fetchProducts();
  }, [minPrice, maxPrice, onResetFilters, fetchProducts]);

  const handleApplyFilter = () => {
    setIsApplying(true);
    fetchProducts();
    setTimeout(() => {
      setIsApplying(false);
    }, 500);
  };

  return (
    <div className={isOffCanvas ? "" : "filter-sidebar"}>
      <Container fluid={isOffCanvas}>
        <div>
          <h5>FILTER PRODUCTS</h5>
          <hr />
          <div className="filter-group mt-3 filter-section">
            <label htmlFor="price-range" className="mb-4">
              <span>
                <h4>PRICE</h4>
              </span>
            </label>

            <Range
              step={1}
              min={minPrice}
              max={maxPrice}
              values={localPriceRange}
              onChange={(values) => setLocalPriceRange(values)}
              onFinalChange={onPriceChange}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "8px",
                    width: "100%",
                    background: "linear-gradient(to right, #FEFAE0,  #1E201E)",
                    borderRadius: "4px",
                    margin: "10px 0",
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ index, props }) => {
                return (
                  <div
                    {...props}
                    key={props.key}
                    style={{
                      ...props.style,
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      backgroundColor: "#FFFBE6",
                      border: "2px solid #007bff",
                      boxShadow: "0 0 5px rgba(0, 123, 255, 0.5)",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-20px",
                        left: "-15px",
                        color: "#fff",
                        backgroundColor: "#1E201E",
                        padding: "2px 6px",
                        borderRadius: "16px",
                        fontWeight: "bold",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      £{localPriceRange[index]}
                    </div>
                  </div>
                );
              }}
            />
            <div className="price-range-label">
              <span>
                <span className="range-text ms-1 ">Price Range:</span>
                <span className="price-value ms-2">
                  <span className="pound-sign">£</span>
                  {localPriceRange[0]} - <span className="pound-sign">£</span>
                  {localPriceRange[1]}
                </span>
              </span>
            </div>
          </div>

          <div className="filter-group mt-3">
            <label htmlFor="sub-category" className="mb-2">
              <span>
                <h4>CATEGORY</h4>
              </span>
            </label>
            <Select
              isMulti
              name="sub-category"
              options={subCategories.map((sub) => ({
                label: sub,
                value: sub,
              }))}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="All"
              onChange={handleSubCategoryChange}
              value={selectedSubCategories.map((sub) => ({
                label: sub,
                value: sub,
              }))}
            />
          </div>

          {attrs &&
            attrs.map((attr) => (
              <div key={attr.key} className="filter-group mt-3">
                <label htmlFor={attr.key} className="mb-2">
                  <span>
                    <h4>{attr.key}</h4>
                  </span>
                </label>
                <Select
                  placeholder="All"
                  isMulti
                  name={attr.key}
                  options={getAttributeOptions(attr.key)}
                  value={(selectedAttributes[attr.key] || []).map((value) => ({
                    label: value,
                    value,
                    key: `${attr.key}-${value}`,
                  }))}
                  onChange={(selectedOptions) =>
                    handleAttributeChange(attr.key, selectedOptions)
                  }
                />
              </div>
            ))}

          <Row>
            <Col>
              <Button
                variant="primary"
                className="mt-3 "
                onClick={handleApplyFilter}
                disabled={isApplying}
              >
                {isApplying ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Apply Filter"
                )}
              </Button>
            </Col>
            <Col>
              <Button variant="danger" className="mt-3 " onClick={resetFilters}>
                Reset Filters
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default FilterSidebar;
