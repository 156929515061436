import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";
import ProductCard from "../../components/cards/ProductCard";

const CategoryPage = () => {
  const { categoryName } = useParams();
  const [category, setCategory] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCategoryProductsApiRequest = async (name) => {
    try {
      const { data } = await axios.get(`/api/categories/${name}/products`);
      return data;
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message || "Unknown error";

      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchCategoryProductsApiRequest(categoryName)
      .then((res) => {
        setCategory(res.category);
        setProducts(res.products);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message || "Unknown error";

        toast.error(errorMessage);
      });
  }, [categoryName]);
  return (
    <Container>
      <Row>
        <Col>
          {loading ? (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              arial-hidden="true"
            />
          ) : (
            <h1 className="text-centre ">{category.name}</h1>
          )}

          <Row>
            {products &&
              products.map((product) => (
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  key={product._id}
                  className="d-flex justify-content-center"
                >
                  <ProductCard product={product} />
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CategoryPage;
