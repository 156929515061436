import React from 'react';
import AdminProductsPageComponent from './components/AdminProductsPageComponent';
import axios from 'axios';
const listProducts = async (controller) => {
  const { data } = await axios.get('/api/products/admin', {
    signal: controller.signal,
  });
  return data;
};

const deleteProduct = async (id) => {
  const { data } = await axios.delete(`/api/products/admin/delete/${id}`);
  return data;
};
const AdminProductsPage = () => {
  // In parent component

  return (
    <AdminProductsPageComponent
      listProducts={listProducts}
      deleteProduct={deleteProduct}
    />
  );
};

export default AdminProductsPage;
