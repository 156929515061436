import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

const AddAttributes = ({
  newAttributeValue,
  handleAddAttrsAndSaveToCategoryDocument,
  handleNewAttributeKey,
  handleNewAttributeValue,
  newAttributeKey,
}) => {
  return (
    <div>
      <Row>
        <Form.Text className='text-warning mb-4'>
          <b>
            After typing attribute key and value press enter on one of the
            fields
          </b>
        </Form.Text>
        <Col md={6}>
          <Form.Group
            className='mb-3'
            controlId='formBasicNewAttribute'
          >
            <Form.Label>Create new attribute</Form.Label>
            <Form.Control
              placeholder='Please enter attribute'
              name='newAttrKey'
              type='text'
              value={newAttributeKey}
              onChange={handleNewAttributeKey}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group
            className='mb-3'
            controlId='formBasicNewAttributeValue'
          >
            <Form.Label>Attribute value</Form.Label>
            <Form.Control
              placeholder='Please enter value'
              name='newAttrValue'
              value={newAttributeValue}
              onChange={handleNewAttributeValue}
              type='text'
            />
          </Form.Group>
        </Col>
        <Col md={12}>
          <Button onClick={handleAddAttrsAndSaveToCategoryDocument}>
            Add to attributes table
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default AddAttributes;
