import React, { useState, useEffect } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../image-zoom.css";

const ProductImageZoom = ({ product }) => {
  const [mainImage, setMainImage] = useState("");

  useEffect(() => {
    if (product.images && product.images.length > 0) {
      setMainImage(product.images[0].uri);
    }
  }, [product.images]);

  if (!product.images || product.images.length === 0) {
    return <div className="no-images">No Images Found</div>;
  }

  return (
    <div className="image-zoom-container">
      <Carousel
        showThumbs={true}
        showStatus={false}
        showIndicators={true}
        onClickItem={(index) => setMainImage(product.images[index].uri)}
        selectedItem={product.images.findIndex(
          (image) => image.uri === mainImage
        )}
      >
        {product.images.map((image, idx) => (
          <div key={idx} className="carousel-slide">
            <TransformWrapper
              initialScale={1}
              initialPositionX={0}
              initialPositionY={0}
              wheel={{ step: 0.2 }}
              pan={{ lockAxisX: false, lockAxisY: false }}
              pinch={{ step: 5 }}
            >
              <TransformComponent>
                <img
                  src={image.uri}
                  alt={product.name}
                  className="zoom-image"
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ProductImageZoom;
