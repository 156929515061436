import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import UploadImages from "../../../components/product/UploadImages";
import { useDispatch } from "react-redux";
const AdminCreateSubsPageComponent = ({
  createSubCategoryReduxAction,
  categories,
}) => {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    const formData = new FormData();

    formData.append("name", form.elements["name"].value);
    formData.append("description", form.elements["description"].value);
    formData.append("parentId", form.elements["parentId"].value);
    formData.append("images", JSON.stringify(images));
    // Handling file object correctly
    dispatch(
      createSubCategoryReduxAction(formData, (subId) => {
        if (subId) {
          toast.success("Sub category was created successfully");
          navigate("/admin/subs");
        } else {
          toast.error("Failed to create sub category");
        }
      })
    );

    setValidated(true);
  };

  return (
    <Container>
      <Row className="justify-content-md-center mt-4">
        <Col md={6}>
          <h1>Create Sub</h1>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" required name="name" />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control type="text" required name="description" />
            </Form.Group>

            <Form.Group>
              <Form.Label>Parent Category</Form.Label>
              <select
                name="parentId"
                className="form-control"
                required // Ensure this is added to enforce selection
              >
                <option value="">Select a category</option>
                {categories &&
                  categories.map((category, idx) => (
                    <option value={category._id} key={idx}>
                      {category.name}
                    </option>
                  ))}
              </select>
            </Form.Group>
            <UploadImages
              images={images}
              setImages={setImages}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
            <Button type="submit"> Create </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminCreateSubsPageComponent;
