import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
} from "react-bootstrap";

import CartItemComponent from "../../../components/cart/CartItemComponent";
import { toast } from "react-toastify";
import ShippingDetailsCard from "../../../components/user/ShippingDetailsComponent";

const UserCartDetailsPageComponent = ({
  itemsCount,
  cartItems,
  cartSubtotal,
  addToCartReduxAction,
  removeProductFromCartAction,
  reduxDispatch,
  userInfo,
  fetchUser,

  createUserOrder,
}) => {
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [userAddress, setUserAddress] = useState(false);
  const [missingAddress, setMissingAddress] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const changeCount = (productId, quantity) => {
    reduxDispatch(addToCartReduxAction(productId, quantity));
  };
  const removeFromCartHandler = (productId) => {
    if (window.confirm("Are you sure?"))
      reduxDispatch(removeProductFromCartAction(productId));
  };
  useEffect(() => {
    fetchUser(userInfo._id)
      .then((res) => {
        if (
          (!res.phoneNumber && res.phoneNumber === undefined) ||
          (!res.address && res.address === undefined) ||
          (!res.country && res.country === undefined) ||
          (!res.postalCode && res.postalCode === undefined) ||
          (!res.city && res.city === undefined) ||
          (!res.state && res.state === undefined)
        ) {
          setButtonDisabled(true);
          setMissingAddress(
            "Please ensure your profile details are accurate to proceed with your purchase."
          );
        } else {
          setUserAddress({
            address: res.address,
            phoneNumber: res.phoneNumber,
            country: res.country,
            postalCode: res.postalCode,
            city: res.city,
            state: res.state,
          });
          setMissingAddress(false);
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Unknown error occurred!";
        toast.error(errorMessage);
      });
  }, [fetchUser, userInfo]);

  const orderHandler = async () => {
    const orderData = {
      orderTotal: {
        itemsCount: itemsCount,
        cartSubtotal: cartSubtotal,
      },
      cartItems: cartItems.map((item) => {
        return {
          productID: item.productID,
          name: item.name,
          price: item.price,
          image: {
            uri: item.image ? item.image.uri ?? null : null, // Only use uri directly
            path:
              item.image && item.image.path ? item.image.path : "default_path",
          },
          quantity: item.quantity,
          count: item.count,
        };
      }),
      paymentMethod: paymentMethod,
      images: cartItems.map((item) => {
        // Collect images with public_id and uri for another purpose in the same order data
        return {
          public_id: item.image
            ? item.image.public_id ?? "default_public_id"
            : "default_public_id",
          uri: item.image ? item.image.uri ?? null : null,
        };
      }),
    };

    await createUserOrder(orderData)
      .then((res) => {
        if (res) {
          navigate(`/user/order-details/${res._id}`);
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.response.data || "Unknown error occurred!"; // Added error.message to catch other potential messages

        toast.error(errorMessage);
      });
  };

  const choosePaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  };
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Row>
          <Col>
            <h1 className="mt-4 mb-4 text-primary">Cart Details</h1>
          </Col>
        </Row>

        <Col md={8}>
          <Row>
            <ShippingDetailsCard
              userInfo={userInfo}
              userAddress={userAddress}
            />
            <Col md={6}>
              <ListGroup variant="flush">
                {" "}
                <h3>Payment Method</h3>
                <Form.Select
                  onChange={choosePaymentMethod}
                  value={paymentMethod}
                >
                  <option
                    disabled={!paymentMethod && paymentMethod === ""}
                    value=""
                  >
                    Please Select
                  </option>
                  <option value="pp">Paypal</option>
                  <option value="stripe">Stripe</option>
                </Form.Select>
              </ListGroup>
            </Col>
            <Row>
              <Col>
                <Alert className="mt-3 mb-3" variant="dark">
                  <p> Delivery pending</p>
                  <p> {missingAddress}</p>
                  {missingAddress ? (
                    <Link to="/user">
                      <p className="text-danger">
                        Please provide your details to proceed with your order.
                      </p>
                    </Link>
                  ) : (
                    ""
                  )}
                </Alert>
              </Col>

              <Col>
                <Alert className="mt-3 mb-3" variant="dark">
                  <p>Awaiting payment</p>
                </Alert>
              </Col>
            </Row>
          </Row>
          <br />
          <h2>Order items</h2>
          <ListGroup variant="flush">
            {cartItems.length > 0 &&
              cartItems.map((item, idx) => (
                <ListGroup.Item key={idx}>
                  <CartItemComponent
                    item={item}
                    changeCount={changeCount}
                    removeFromCartHandler={removeFromCartHandler}
                  />
                </ListGroup.Item>
              ))}
          </ListGroup>
        </Col>

        <Col md={4} className="mt-2">
          <ListGroup>
            <ListGroup.Item>
              <h3>Order Summary</h3>
            </ListGroup.Item>
            <ListGroup.Item>
              Items price (after tax):{" "}
              <span className="fw-bold">£ {cartSubtotal}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              Shipping: <span className="fw-bold">Included</span>
            </ListGroup.Item>
            <ListGroup.Item>
              Tax: <span className="fw-bold">Included</span>
            </ListGroup.Item>
            <ListGroup.Item className="text-danger">
              Total price: <span className="fw-bold">£{cartSubtotal}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="d-grid gap-2">
                <Button
                  size="lg"
                  variant="primary"
                  type="submit"
                  onClick={orderHandler}
                  disabled={buttonDisabled}
                >
                  Place order
                </Button>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default UserCartDetailsPageComponent;
