import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const UserProfilePageComponent = ({
  updateUserReduxAction,
  userInfo,
  reduxDispatch,
  isLoading,
  error,
}) => {
  const [formData, setFormData] = useState({
    name: userInfo.name,
    lastName: userInfo.lastName,
    phoneNumber: userInfo.phoneNumber || "",
    address: userInfo.address || "",
    country: userInfo.country || "",
    postalCode: userInfo.postalCode || "",
    city: userInfo.city || "",
    state: userInfo.state || "",
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [passwordsMatchState, setPasswordsMatchState] = useState(true);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "password" || name === "confirmPassword") {
      setPasswordsMatchState(
        name === "password"
          ? value === formData.confirmPassword
          : formData.password === value
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let isValid = true;

    if (formData.password && formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match.");
      setPasswordsMatchState(false);
      isValid = false;
    }

    if (form.checkValidity() === false) {
      e.stopPropagation();
      isValid = false;
    }

    if (isValid) {
      reduxDispatch(updateUserReduxAction(formData, () => navigate("/user")));
    }

    setValidated(true);
  };

  return (
    <Container className="auth-container" fluid>
      <Row className="display-flex justify-content-md-center mb-3 mt-3 p-4">
        <Col md={6}>
          <h1 className="text-dark">{userInfo.name} Profile</h1>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="auth-form"
          >
            <Form.Group
              className="mb-3 auth-form"
              controlId="validationCustom01"
            >
              <Form.Label>Your name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter your name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a name
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicLastName">
              <Form.Label>Your last name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter your last name"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please enter your last name
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                name="phoneNumber"
                required
                type="text"
                placeholder="Enter your phone number"
                value={formData.phoneNumber}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid phone number
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                name="address"
                required
                type="text"
                placeholder="Enter your street name, building name and house number"
                value={formData.address}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please enter an address
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCountry">
              <Form.Label>Country</Form.Label>
              <Form.Control
                name="country"
                required
                type="text"
                placeholder="Enter your country"
                value={formData.country}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a country
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPostalCode">
              <Form.Label>Post Code</Form.Label>
              <Form.Control
                name="postalCode"
                required
                type="text"
                placeholder="Enter your Postal Code"
                value={formData.postalCode}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a postal code
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCity">
              <Form.Label>City</Form.Label>
              <Form.Control
                name="city"
                required
                type="text"
                placeholder="Enter your city"
                value={formData.city}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a city
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicState">
              <Form.Label>State</Form.Label>
              <Form.Control
                name="state"
                required
                type="text"
                placeholder="Enter your state"
                value={formData.state}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a state
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                disabled
                value={`${userInfo.email}. For email modifications, please delete the current account and register anew with the desired email address.`}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCurrentPassword">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                name="currentPassword"
                type="password"
                placeholder="Enter current password"
                required
                value={formData.currentPassword}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please enter your current password
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                name="password"
                type="password"
                placeholder="Enter new password"
                minLength={6}
                value={formData.password}
                onChange={handlePasswordChange}
                isInvalid={!passwordsMatchState}
              />
              <Form.Control.Feedback type="invalid">
                Password should have at least 6 characters
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPasswordRepeat">
              <Form.Label>Repeat New Password</Form.Label>
              <Form.Control
                name="confirmPassword"
                type="password"
                placeholder="Repeat new password"
                minLength={6}
                value={formData.confirmPassword}
                onChange={handlePasswordChange}
                isInvalid={!passwordsMatchState}
              />
              <Form.Control.Feedback type="invalid">
                Both passwords should match
              </Form.Control.Feedback>
            </Form.Group>
            {isLoading && <Spinner animation="border" />}
            <Button type="submit" className="button btn btn-info">
              Update your profile
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfilePageComponent;
