import * as actionTypes from '../constants/searchConstants';
export const searchReducer = (state = { text: '' }, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_QUERY:
      return { ...state, ...action.payload };
    case actionTypes.CLEAR_TEXT_FILTER:
      return '';
    case actionTypes.UPDATE_TEXT_FILTER: // Updates the state to the new text from the action payload
      return { ...action, text: action.payload };
    default:
      return state;
  }
};
