import React from "react";
import Select from "react-select";
import "../../css/shared-styles.css"; // Import shared styles

const SortFilter = ({ sortBy, onSortChange }) => {
  const sortOptions = [
    { value: "relevance", label: "Relevance" },
    { value: "priceAsc", label: "Price: Low to High" },
    { value: "priceDesc", label: "Price: High to Low" },
  ];

  return (
    <div className="filter-group mt-3">
      <div className="sort-filter-inline">
        <strong>SORT BY</strong>
        <Select
          id="sort-select"
          name="sortBy"
          options={sortOptions}
          value={sortOptions.find((option) => option.value === sortBy)}
          onChange={(selected) => onSortChange(selected)}
          placeholder="Sort By"
          classNamePrefix="react-select"
        />
      </div>
    </div>
  );
};

export default SortFilter;
