import React, { useState } from "react";
import HeaderComponent from "./HeaderComponent";
import Sidebar from "./Sidebar";

const ResponsiveLayout = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const handleSidebarClose = () => setShowSidebar(false);
  const handleSidebarShow = () => setShowSidebar(true);

  return (
    <>
      <HeaderComponent onSidebarShow={handleSidebarShow} />
      <Sidebar show={showSidebar} handleClose={handleSidebarClose} />
    </>
  );
};

export default ResponsiveLayout;
