import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import UploadImages from '../../../components/product/UploadImages';
const AdminEditCategoryPageComponent = ({
  fetchCategoryApiRequest,
  reduxDispatch,
  editCategoryReduxAction,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    fetchCategoryApiRequest(id).then((res) => {
      setCategory(res);
      if (res.images && res.images.length > 0) {
        setImages(res.images);
      } else {
        setImages([]);
      }
    });
  }, [fetchCategoryApiRequest, id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    const formData = new FormData();

    formData.append('images', JSON.stringify(images));
    formData.append('name', form.elements['name'].value);
    form.append('description', form.elements['description'].value);

    if (form.checkValidity() === true) {
      reduxDispatch(editCategoryReduxAction(id, formData));
      navigate('/admin/categories');
    }
    setValidated(false);
  };

  return (
    <Container>
      <Row>
        <Col md={9}>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Form.Group controlId='formBasicControlEditCategoryName'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                name='name'
                defaultValue={category.name}
                required
                type='text'
              ></Form.Control>
            </Form.Group>
            <Form.Group className='formBasicControlEditCategoryDescription'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                name='description'
                defaultValue={category.description}
                required
                type='text'
              ></Form.Control>
            </Form.Group>
            <UploadImages
              images={images}
              setImages={setImages}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
            <Form.Group formBasicControlEditCategoryUpdateButton>
              <Form.Label />
              <Button type='submit'>Update Category</Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminEditCategoryPageComponent;
