import React, { Fragment, useEffect, useState } from "react";
import { Button, Nav, Offcanvas } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import NavLinks from "./NavLinks";
import "../../css/sidebar.css";

const Sidebar = ({ show, handleClose }) => {
  const { categories } = useSelector((state) => state.getCategories);
  const { subs } = useSelector((state) => state.getSubs);
  const [categorySubs, setCategorySubs] = useState({});
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 787);

  useEffect(() => {
    const subsByCategory =
      categories &&
      categories.reduce((acc, category) => {
        acc[category._id] =
          subs && subs.filter((sub) => sub.parentId === category._id);
        return acc;
      }, {});
    setCategorySubs(subsByCategory);
  }, [categories, subs]);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 787);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleDropdownClick = (categoryId) => {
    setActiveDropdown((prevState) =>
      prevState === categoryId ? null : categoryId
    );
  };

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="start"
      className="off-canvas-sidebar"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="offcanvas-title">
          READY TO WEAR
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <NavLinks handleClose={handleClose} inSidebar={true} />

        <Nav className="flex-column mt-4">
          {categories &&
            categories.length > 0 &&
            categories.map((category, idx) => (
              <div key={idx}>
                <div
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => handleDropdownClick(category._id)}
                >
                  <Link
                    to={`/${category.name.toLowerCase()}`}
                    onClick={handleClose}
                    className="nav-link"
                  >
                    <h3>{category.name}</h3>
                  </Link>

                  {!isLargeScreen && (
                    <FontAwesomeIcon
                      icon={
                        activeDropdown === category._id
                          ? faAngleUp
                          : faAngleDown
                      }
                      className="ms-2"
                    />
                  )}
                </div>
                {activeDropdown === category._id && (
                  <div
                    className={`sidebar-nav-dropdown-menu ${
                      activeDropdown === category._id ? "active" : ""
                    }`}
                  >
                    {categorySubs[category._id] &&
                      categorySubs[category._id].map((sub) => (
                        <div className="flex-column mt-4" key={sub._id}>
                          <Link
                            className="sidebar-nav-dropdown-item"
                            to={`/sub/${sub.name}`}
                            onClick={handleClose}
                          >
                            <h4> {sub.name}</h4>
                          </Link>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            ))}
        </Nav>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Sidebar;
