import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import { BsEye, BsEyeSlash } from "react-icons/bs"; // Import eye icons for visibility toggle
import { toast } from "react-toastify";
import "../../css/sign-in.css";
const SignInPageComponent = ({ signInUserReduxAction }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo, error, isLoading } = useSelector((state) => state.authUser);

  const [validated, setValidated] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      dispatch(signInUserReduxAction(formData, () => navigate("/")));
    } else {
      event.stopPropagation();
      toast.error("Please fill in the form correctly.");
    }
    setValidated(true);
  };

  return (
    <Container fluid className="auth-container">
      <Row className="sign-in-form">
        <Col md={6}>
          <h2>Sign in</h2>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                <h4>Email address</h4>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                required
                value={formData.email}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>
                <h4>Password</h4>
              </Form.Label>
              <div className="password-input-container">
                <Form.Control
                  type={passwordShown ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  required
                  value={formData.password}
                  onChange={handleInputChange}
                  style={{ paddingRight: "30px" }} // Ensure padding to prevent text overlap, if not set by CSS
                />
                <span
                  className="password-toggle-icon"
                  onClick={togglePasswordVisibility}
                  tabIndex="0" // Make it focusable
                  aria-label={passwordShown ? "Hide password" : "Show password"}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") togglePasswordVisibility();
                  }} // Allow toggle with Enter key
                >
                  {passwordShown ? <BsEyeSlash /> : <BsEye />}
                </span>
              </div>
              <Form.Control.Feedback type="invalid">
                Please provide a valid password.
              </Form.Control.Feedback>
            </Form.Group>

            <Button className="login-button" type="submit" disabled={isLoading}>
              {isLoading ? "Loading..." : <h4>Sign in</h4>}
            </Button>
          </Form>
          <div className="reset-password">
            <Link to="/request-reset">
              <Button>
                <h5>Forgot Password?</h5>
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <Row className="register-link">
        <Col md={6}>
          <h4>Don't have an account?</h4>
          <Link to="/register" className="text-primary">
            <Button>
              <h5> Register</h5>
            </Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default SignInPageComponent;
