import React from 'react';
import AdminEditSubPageComponent from './components/AdminEditSubPageComponent';
import axios from 'axios';
import { useSelector } from 'react-redux';

const updateSub = async (id, formData) => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  const { data } = await axios.put(`/api/subs/update/${id}`, formData, config);
  return { data };
};

const fetchSub = async (id) => {
  const { data } = await axios.get(`/api/subs/get-one/${id}`);
  return data;
};

const AdminEditSubPage = () => {
  const { categories } = useSelector((state) => state.getCategories);
  return (
    <AdminEditSubPageComponent
      updateSub={updateSub}
      categories={categories}
      fetchSub={fetchSub}
    />
  );
};

export default AdminEditSubPage;
