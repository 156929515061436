import React from 'react';
import { Form, CloseButton } from 'react-bootstrap';

const CategorySelector = ({
  categories,
  categoryChosen,
  handleDeleteCategoryHandler,
  handleCategoryChange,
}) => {
  return (
    <div>
      <Form.Group
        className='mb-3'
        controlId='formBasicCategory'
      >
        <Form.Label>
          Category
          <CloseButton onClick={handleDeleteCategoryHandler} />(
          <small>remove selected</small>)
        </Form.Label>
        <Form.Select
          required
          id='categoryChosen'
          name='category'
          value={categoryChosen}
          onChange={handleCategoryChange}
          aria-label='Default select example'
        >
          <option value=''>Choose category</option>
          {categories &&
            categories.map((c) => (
              <option
                key={c._id}
                value={c._id}
              >
                {c.name}
              </option>
            ))}
        </Form.Select>
      </Form.Group>
    </div>
  );
};

export default CategorySelector;
