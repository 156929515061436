import * as actionTypes from "../../constants/auth/userConstants";
import api from "../../../axiosConfig";
import { toast } from "react-toastify";
import axios from "axios";

export const signInUserReduxAction =
  (userData, callBack) => async (dispatch) => {
    dispatch({ type: actionTypes.Auth_REQUEST });

    try {
      const { data } = await api.post("/users/sign-in", userData); // Adjust the path as necessary

      dispatch({
        type: actionTypes.LOGIN_USER,
        payload: data.user,
      });

      localStorage.setItem("userInfo", JSON.stringify(data.user));
      localStorage.setItem("expiration", Date.now() + 86400000 * 7); // Set for 7 days

      toast.success("Login successful");
      if (callBack) callBack(); // Execute callback if provided
    } catch (error) {
      let errorMessage = "An unknown error occurred. Please try again later.";
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      } else if (error.message) {
        errorMessage = error.message; // Network errors, etc.
      }
      dispatch({
        type: actionTypes.LOGIN_FAILURE,
        payload: errorMessage,
      });
      toast.error(errorMessage);
    }
  };

export const logoutUserReduxAction = () => (dispatch) => {
  //clear local storage
  localStorage.removeItem("userInfo");
  localStorage.removeItem("expiration");
  localStorage.removeItem("cart");
  // Additional cleanup if needed
  dispatch({ type: actionTypes.LOGOUT_USER });
  //optionally: remove the JWT cookie
};

export const registerUserReduxAction =
  (userData, callBack) => async (dispatch) => {
    try {
      dispatch({ type: actionTypes.Auth_REQUEST });
      const { data } = await api.post(`/users/register`, userData);
      dispatch({
        type: actionTypes.REGISTER_USER,
        payload: data.user, // Adjust based on the actual response structure
      });
      localStorage.setItem("userInfo", JSON.stringify(data.user));
      localStorage.setItem("expiration", Date.now() + 86400000); // 24 hrs
      callBack && callBack();
      toast.success("User registered successfully");
    } catch (error) {
      console.error("Registration Error: ", error);
      let errorMessage = "An unknown error occurred. Please try again later.";
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      dispatch({
        type: actionTypes.REGISTER_FAILURE,
        payload: errorMessage,
      });
      toast.error(errorMessage);
    }
  };

export const updateUserReduxAction =
  (userData, callBack) => async (dispatch) => {
    try {
      dispatch({ type: actionTypes.USER_UPDATE_REQUEST });

      const { data } = await api.put("/users/get-one/update", userData);

      dispatch({ type: actionTypes.USER_UPDATE_SUCCESS, payload: data });
      dispatch({ type: actionTypes.USER_LOGIN_SUCCESS, payload: data });

      localStorage.setItem("userInfo", JSON.stringify(data));

      if (callBack && typeof callBack === "function") {
        callBack();
      }

      toast.success("User updated successfully");
    } catch (error) {
      console.error("Update Error: ", error);
      let errorMessage = "An unknown error occurred. Please try again later.";

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }

      dispatch({
        type: actionTypes.USER_UPDATE_FAIL,
        payload: errorMessage,
      });
      toast.error(errorMessage);
    }
  };
