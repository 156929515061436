import React from 'react';
import UserOrderDetailsPageComponent from './components/UserOrderDetailsPageComponent';
import { loadScript } from '@paypal/paypal-js';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';

const fetchUserOrder = async (orderId) => {
  const { data } = await axios.get(`/api/orders/user/${orderId}`);
  return { data };
};
const fetchUser = async (id) => {
  const { data } = await axios.get(`/api/users/profile/${id}`);
  return data;
};
const loadPayPalScript = (
  cartSubtotal,
  cartItems,
  orderId,
  updateStateAfterSuccessfulPayment
) => {
  loadScript({
    clientId:
      'AZzUy6BYMRtaygRs3JV1Xp0OMUanCoVu3i_tNNLkFb-Ig3HpzLqm556gY2MkSiLJ9Cn5c9hO_4l3xnmg',
    currency: 'GBP',
  })
    .then((paypal) => {
      paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: cartSubtotal,
                    breakdown: {
                      item_total: {
                        currency_code: 'GBP',
                        value: cartSubtotal,
                      },
                    },
                  },
                  items: cartItems.map((product) => {
                    return {
                      name: product.name,
                      unit_amount: {
                        currency_code: 'GBP',
                        value: product.price,
                      },
                      quantity: product.quantity,
                    };
                  }),
                },
              ],
            });
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then((orderData) => {
              var transaction =
                orderData.purchase_units[0].payments.captures[0];
              if (
                transaction.status === 'COMPLETED' &&
                Number(transaction.amount.value) === Number(cartSubtotal)
              ) {
                updateOrder(orderId)
                  .then((res) => {
                    if (res.isPaid) {
                      updateStateAfterSuccessfulPayment(res.paidAt);
                    }
                  })
                  .catch((error) => {
                    const errorMessage =
                      error.res && error.res.data && error.res.data.message
                        ? error.res.data.message
                        : error.res.data;
                    toast.error(errorMessage);
                  });
              }
            });
          },
          onCancel: cancelHandler,
          onError: errorHandler,
        })
        .render('#paypal-container-element');
    })

    .catch((error) => {
      throw error;
    });
};
const updateOrder = async (orderId) => {
  const { data } = await axios.put(`/api/orders/paid/${orderId}`);
  return data;
};

const cancelHandler = () => {
  //
};
const errorHandler = () => {
  //
};

const UserOrderDetailsPage = () => {
  const { userInfo } = useSelector((state) => state.authUser);
  return (
    <UserOrderDetailsPageComponent
      userInfo={userInfo}
      fetchUserOrder={fetchUserOrder}
      fetchUser={fetchUser}
      loadPayPalScript={loadPayPalScript}
    />
  );
};

export default UserOrderDetailsPage;
