import React from 'react';
import { useDispatch } from 'react-redux';
import AdminCreateCategoryPageComponent from './components/AdminCreateCategoryPageComponent';
import { createCategoryReduxAction } from '../../redux/actions/category/categoryActions';

const AdminCreateCategoryPage = () => {
  const dispatch = useDispatch();
  const reduxDispatch = dispatch;
  return (
    <AdminCreateCategoryPageComponent
      createCategoryReduxAction={createCategoryReduxAction}
      reduxDispatch={reduxDispatch}
    />
  );
};

export default AdminCreateCategoryPage;
