import * as actionTypes from '../../constants/chat/chatConstants';

export const setChatRoomReduxAction = (user, message) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CHAT_ROOMS,
    payload: {
      user: user,
      message: message,
    },
  });
};

export const setSocketReduxAction = (socket) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SOCKET,
    payload: {
      socket: socket,
    },
  });
};
export const setMessageReceivedReduxAction = (value) => (dispatch) => {
  dispatch({
    type: actionTypes.MESSAGE_RECEIVED,
    payload: {
      value: value, // true or false
    },
  });
};

export const removeChatRoomReduxAction = (socketId) => async (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_CHAT_ROOM,
    payload: {
      socketId: socketId,
    },
  });
};
