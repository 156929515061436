import React from "react";
import { Button } from "react-bootstrap";

const FilterActions = ({ onResetFilters }) => {
  return (
    <div className="filter-group mt-3">
      <Button onClick={onResetFilters} variant="secondary" className="ms-2 p-2">
        Reset Filters
      </Button>
    </div>
  );
};

export default FilterActions;
